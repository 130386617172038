<template>
  <div class="flex flex-col items-center flex-1 w-full p-12 overflow-scroll bg-slate-50">
    <div class="flex flex-wrap justify-center w-2/3 h-auto pt-8 px-11 md:flex-row">
      <p
        class="text-gray-500"
      >
        {{ $t('profile.memberships.noTeams') }}
      </p>
    </div>
  </div>
</template>
