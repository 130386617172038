<template>
  <div>
    <i18n-t
      class="mb-4 text-xs md:text-sm"
      :keypath="`changelog.explanation.${type}`"
      tag="p"
      scope="global"
    >
      <span class="font-bold text-purple-500">
        @{{ username }}
      </span>
    </i18n-t>
    <mute-banner
      v-if="Object.keys(groupedChangelogItems).length === 0"
      kind="info"
    >
      <p>{{ $t('changelog.noChangelogItems') }} </p>
    </mute-banner>
    <div
      v-for="(value, key, indexMonth) in groupedChangelogItems"
      :key="value.id"
    >
      <div class="mb-2 text-base font-semibold text-purple-500">
        {{ key }}
      </div>
      <div
        v-for="(item) in value"
        :key="item.id"
      >
        <div
          class="flex-col my-3"
        >
          <div class="mb-2 text-sm font-bold">
            {{ item.title }}
          </div>
          <render-html
            :code="item.descriptionHtml"
          />
        </div>
      </div>
      <mute-divider
        v-if="indexMonth !== Object.keys(groupedChangelogItems).length -1 "
        class="my-4"
        width="w-full"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import changelogItemsApi from '../../api/changelog_items';
import renderHtml from '../changelog/render-html';
import MuteBanner from '../shared/mute-banner.vue';

export default {
  components: { renderHtml, MuteBanner },
  props: {
    type: { type: String, required: true },
    changelogItemsCurrent: { type: Array, required: false, default: null },
    username: { type: String, required: true },
  },
  data() {
    return {
      changelogItems: this.type === 'recent' ? this.changelogItemsCurrent : null,
      loading: false,
      errors: '',
    };
  },
  computed: {
    groupedChangelogItems() {
      moment.locale(window.localStorage.locale);

      return groupBy(
        this.changelogItems,
        changelogItem => moment(changelogItem.createdAt).format('MMMM, YYYY'),
      );
    },
  },
  async mounted() {
    if (this.type === 'all') {
      await this.fetchAllChangelogItems();
    }
  },
  methods: {
    closeChangelogModal() {
      this.$modal.hide('changelog-modal');
    },
    async fetchAllChangelogItems() {
      this.loading = true;
      changelogItemsApi.getChangelogItems()
        .then((response) => {
          this.changelogItems = response.data.changelogItems;
        })
        .catch((error) => {
          if (error.details) {
            this.errors = error.details;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
