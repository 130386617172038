<template>
  <div>
    <landing-nav-bar :is-hero-visible="isHeroVisible" />
    <landing-hero v-observe-visibility="heroVisibilityChanged" />
    <landing-companies />
    <landing-features />
    <landing-pre-footer />
    <landing-footer />
  </div>
</template>

<script>
import LandingHero from '../components/landing-page/landing-hero.vue';
import LandingNavBar from '../components/landing-page/landing-nav-bar.vue';
import LandingCompanies from '../components/landing-page/landing-companies.vue';
import LandingFeatures from '../components/landing-page/landing-features.vue';
import LandingPreFooter from '../components/landing-page/landing-pre-footer.vue';
import LandingFooter from '../components/landing-page/landing-footer.vue';

export default {
  components: {
    LandingNavBar,
    LandingHero,
    LandingCompanies,
    LandingFeatures,
    LandingPreFooter,
    LandingFooter,
  },
  data() {
    return {
      isHeroVisible: true,
    };
  },
  methods: {
    heroVisibilityChanged(isVisible) {
      this.isHeroVisible = isVisible;
    },
  },
};
</script>
