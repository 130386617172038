import { serialize } from 'object-to-formdata';
import api from './index.js';

const path = '/api/internal/user';

export default {
  update(body) {
    return api({
      method: 'patch',
      url: path,
      data: body.user.profilePicture ? serialize(body) : body,
    });
  },
};
