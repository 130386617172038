import unreadMessagesApi from '../../api/unread-messages';

const initialState = {
  unreadAnnouncements: [],
  unreadComments: [],
  allowUnreadMessages: false,
};

const getters = {
  unreadCount: (state) => (sectionIndex) => {
    const unreadAnnouncements = state.unreadAnnouncements.filter(
      (u) => u.sectionId === sectionIndex && !state.mutedSections[u.sectionId],
    ).length;
    const unreadComments = state.unreadComments.filter(
      (u) => u.sectionId === sectionIndex &&
      !state.mutedAnnouncements[u.announcementId] &&
      !state.mutedSections[sectionIndex],
    ).length;

    return unreadAnnouncements + unreadComments;
  },
  totalUnreadCount: (state, _getters, rootState) => {
    const document = rootState.document;
    const allowedUnreadAnnouncements = state.unreadAnnouncements.filter(
      unreadAnnouncement => !document.mutedAnnouncements[unreadAnnouncement] &&
                            !document.mutedSections[unreadAnnouncement.sectionId],
    );

    const allowedUnreadComments = state.unreadComments.filter(
      unreadComment => !document.mutedAnnouncements[unreadComment.announcementId] &&
                            !document.mutedSections[unreadComment.sectionId],
    );

    return allowedUnreadComments.length + allowedUnreadAnnouncements.length;
  },
};

const actions = {
  getUnreadMessages({ commit }, documentId) {
    return unreadMessagesApi.getUnreadMessages(documentId)
      .then(({ data }) => {
        commit('setUnreads', data);
      });
  },
  markSectionAsRead({ commit }, sectionId) {
    commit('updateUnreadAnnouncements', { sectionId: sectionId.toString(), type: 'reset' });
  },
  markAnnouncementAsRead({ commit }, announcementId) {
    commit('updateUnreadComments', { announcementId, type: 'reset' });
  },
  toggleUnreadMessages({ commit }, newValue) {
    commit('setAllowUnreadMessages', newValue);
  },
};

const mutations = {
  setUnreads(state, unreadMessages) {
    const unreadAnnouncements = Object.entries(unreadMessages)
      .reduce((acc, [sectionId, sectionAnnouncements]) => {
        const announcements = Object.entries(sectionAnnouncements)
          .map(([announcementId, { unreadComments, unread }]) => (
            { announcementId, sectionId, comments: unreadComments, unread }
          ));

        return acc.concat(announcements);
      }, []);

    state.unreadAnnouncements = unreadAnnouncements
      .filter(({ unread }) => unread)
      .map(({ announcementId, sectionId }) => ({ announcementId, sectionId }));

    state.unreadComments = unreadAnnouncements.reduce((acc, { announcementId, sectionId, comments }) => (
      acc.concat(comments.map(({ id }) => ({ announcementId, commentId: id.toString(), sectionId })))
    ), []);
  },
  updateUnreadAnnouncements(state, { announcementId, sectionId, type }) {
    if (type === 'reset') {
      state.unreadAnnouncements = state.unreadAnnouncements.filter((c) => c.sectionId !== sectionId);
      state.announcementsNotificated = state.announcementsNotificated.filter((c) => c.sectionId !== sectionId);
    } else {
      state.unreadAnnouncements = [...state.unreadAnnouncements, { announcementId, sectionId }];
    }
  },
  updateUnreadComments(state, { commentId, announcementId, sectionId, type }) {
    if (type === 'reset') {
      state.unreadComments = state.unreadComments.filter((c) => c.announcementId !== announcementId);
      state.commentsNotificated = state.commentsNotificated.filter((c) => c.announcementId !== announcementId);
    } else {
      state.unreadComments = [...state.unreadComments, { announcementId, commentId, sectionId }];
    }
  },
  setAllowUnreadMessages(state, newValue) {
    state.allowUnreadMessages = newValue;
  },
};

export default {
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
