import { useForm } from 'vee-validate';

import moment from 'moment';
import * as yup from 'yup';

const SECONDS_IN_WEEK = 604800;

function templateDay(template) {
  if (!template.nextDate) return 0;
  const dayIndex = moment(template.nextDate).day();

  return dayIndex;
}
function templateHour(template) {
  if (!template.nextDate) return '00:00';

  return moment(template.nextDate).format('HH:mm');
}
function templateWeeks(template) {
  if (!template.nextDate) return '1';
  const weeks = template.recurrenceDuration / SECONDS_IN_WEEK;

  return weeks.toString();
}

export default function useTemplateForm({ template }) {
  const form = useForm({
    initialValues: {
      name: template.name || '',
      recurrence: {
        isActive: template.recurrent || false,
        weeks: templateWeeks(template),
        weekDay: templateDay(template),
        hour: templateHour(template),
      },
    },
    validationSchema: yup.object({
      name: yup.string().required(),
    }),
  });

  return form;
}
