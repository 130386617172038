<template>
  <div
    class="flex items-center"
    :class="colorClasses"
  >
    <inline-svg
      class="fill-current"
      :class="iconOnly? 'h-16': 'h-12'"
      :src="require(`assets/images/logo/mute-logo${iconOnly ? '-icon' : ''}`)"
    />
  </div>
</template>

<script>
export default {
  props: { variant: { type: String, default: 'black' }, iconOnly: { type: Boolean, default: false } },

  computed: {
    colorClasses() {
      const classes = {
        black: 'text-black',
        white: 'text-white',
        purple: 'text-purple-500',
      };

      return classes[this.variant];
    },
  },
};
</script>
