import documentConfigurationApi from '../../api/document-configurations';

const initialState = {
  documentConfiguration: null,
};

const getters = {
  commentsAllowed: (state) => () => {
    if (state.documentConfiguration) {
      return state.documentConfiguration.commentsAllowed;
    }

    return false;
  },
  announcementsAllowed: (state) => () => {
    if (state.documentConfiguration) {
      return state.documentConfiguration.announcementsAllowed;
    }

    return false;
  },
};

const actions = {
  updateDocumentConfiguration({ commit }, configuration) {
    commit('setDocumentConfiguration', configuration);
  },
  toggleAnnouncements({ state }, newValue) {
    return documentConfigurationApi.updateDocumentConfiguration(
      state.documentConfiguration.id,
      { announcementsAllowed: newValue },
    );
  },
  toggleComments({ state }, newValue) {
    return documentConfigurationApi.updateDocumentConfiguration(
      state.documentConfiguration.id,
      { commentsAllowed: newValue },
    );
  },
};

const mutations = {
  setDocumentConfiguration(state, payload) {
    state.documentConfiguration = payload;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
