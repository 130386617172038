<template>
  <div class="flex items-center justify-between mb-4">
    <div class="flex items-center h-8 mr-auto text-green-500">
      <a
        class="flex items-center hover:underline mr-2"
        :href="backUrl"
        data-testid="documents-anchor"
      >
        <inline-svg
          :src="require('../../../assets/images/icons/back-arrow-green.svg')"
        />
        {{ $t('templates.topbar.back') }}
      </a>
      <p v-if="textSection">
        {{ `| ${textSection}` }}
      </p>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    backUrl: { type: String, required: true },
    textSection: { type: String, default: '' },
  },
};
</script>

<style>

</style>
