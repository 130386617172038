import { intersection } from 'lodash';

export function getSectionIdFromAnnouncementId(sections, announcementId) {
  const section = Object.values(sections).find((sec) => sec.announcements.includes(Number(announcementId)));

  return section ? section.id : null;
}

export function getSavedAnnouncementIndex(state, payload) {
  const sections = state.savedAnnouncementsByDocument[payload.documentId] || {};
  const announcements = sections[payload.sectionId] || [];

  return announcements.findIndex(item => item === payload.announcementId);
}

export function removeSavedAnnouncement(state, payload) {
  const sections = state.savedAnnouncementsByDocument[payload.documentId] || {};
  sections[payload.sectionId].splice(payload.announcementIndex, 1);
  if (sections[payload.sectionId].length === 0) {
    delete sections[payload.sectionId];
    if (Object.keys(sections).length === 0) {
      delete state.savedAnnouncementsByDocument[payload.documentId];
    }
  }
}

export function addSavedAnnouncement(state, payload) {
  if (!state.savedAnnouncementsByDocument[payload.documentId]) {
    state.savedAnnouncementsByDocument[payload.documentId] = {};
  }
  if (!state.savedAnnouncementsByDocument[payload.documentId][payload.sectionId]) {
    state.savedAnnouncementsByDocument[payload.documentId][payload.sectionId] = [];
  }
  state.savedAnnouncementsByDocument[payload.documentId][payload.sectionId].push(payload.announcementId);
}

export function tagIntersection(tags) {
  return (announcement) => intersection(announcement.tagList, tags).length > 0;
}
