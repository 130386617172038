<template>
  <component
    :is="tag"
    class="flex items-center justify-center h-12 text-xl transition-colors duration-150 ease-in rounded-lg shadow focus:outline-none"
    :disabled="disabled"
    :class="colorClasses"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: { type: String, default: 'button' },
    variant: { type: String, default: 'purple' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    colorClasses() {
      const classes = {
        purple: 'text-white bg-purple-500 hover:bg-purple-600',
        green: 'text-white bg-green-500 hover:bg-green-600',
        red: 'text-white bg-red-500 hover:bg-red-600',
        white: 'text-black bg-white hover:bg-gray-100',
        disabled: 'text-white bg-gray-500 cursor-default',
      };

      return this.disabled ? classes.disabled : classes[this.variant];
    },
  },
};
</script>
