<template>
  <div class="col-span-full">
    <div class="space-y-3 md:space-y-0 md:flex md:items-center whitespace-nowrap">
      <div class="flex items-center">
        <mute-vv-checkbox
          id="checkbox-announcements"
          :name="`${name}.isActive`"
        />
        <p class="font-bold text-sm md:text-base">
          {{ $t('templates.form.recurrent') }}
        </p>
        <i18n-t
          v-if="isActive"
          keypath="templates.form.recurrentDetails.weeks"
          tag="div"
          class="flex items-center pl-2 text-sm md:text-base"
          scope="global"
        >
          <template #week>
            <mute-vv-input
              size="small"
              class="max-w-xs mx-2 grow-0"
              min="1"
              max="8"
              type="number"
              :name="`${name}.weeks`"
            />
          </template>
        </i18n-t>
        <p
          v-if="isActive"
          class="hidden md:block"
        >
          ,
        </p>
      </div>
      <i18n-t
        v-if="isActive"
        keypath="templates.form.recurrentDetails.dayDetail"
        tag="div"
        class="flex items-center md:pl-2 normal-case md:lowercase text-sm md:text-base"
        scope="global"
      >
        <template #day>
          <div class="w-auto mx-2">
            <div class="w-28">
              <mute-vv-select
                v-bind="selectProps"
                :options="weekDayOptions"
                size="small"
                :name="`${name}.weekDay`"
                key-value="id"
              />
            </div>
          </div>
        </template>
        <template #hour>
          <div class="w-24">
            <mute-vv-input
              size="small"
              class="mx-2"
              type="time"
              :name="`${name}.hour`"
            />
          </div>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script>
import times from 'lodash/times';
import { useI18n } from 'vue-i18n';

const DAYS_IN_WEEK = 7;

export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  computed: {
    selectProps() {
      return {
        closeOnSelect: true,
        clearOnSelect: false,
        preserveSearch: true,
        searchable: true,
        customLabel({ label }) {
          return label;
        },
        trackBy: 'id',
      };
    },
    weekDayOptions() {
      return times(DAYS_IN_WEEK, (dayIdx) => ({ id: dayIdx, label: this.t(`templates.form.weekDays.${dayIdx}`) }));
    },
  },
};
</script>
