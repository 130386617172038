import i18n from '../plugins/i18n';

export default function yupI18nErrorMessage(errorKey) {
  if (!errorKey) return errorKey;
  const [, key, argument] = errorKey.match(/(\w+\.\w+)(?:\{([\w\d[\]]*)\})?/) || [];

  if (!key) return errorKey;

  return i18n.global.t(`yup.${key}`, { argument });
}
