<template>
  <div>
    <div class="mb-2 text-xl">
      {{ $t('teams.myTeams') }}
    </div>
    <div class="flex flex-col space-y-2">
      <div
        v-for="(team, teamId) in teams"
        :key="teamId"
        class="flex items-center px-2 py-1"
        :class="{'bg-gray-100': currentTeamId === Number(teamId)}"
      >
        <a
          class="w-full cursor-auto"
          :href="`/teams/${teamId}/documents#/`"
          :disabled="currentTeamId === Number(teamId)"
          :class="{
            'text-purple-500 hover:text-purple-600 hover:underline cursor-pointer':
              currentTeamId !== Number(teamId)
          }"
        >
          {{ team.name }}
        </a>
        <a
          v-if="adminTeamsIds.includes(Number(teamId))"
          class="ml-auto text-xs text-purple-500 hover:underline hover:text-purple-600"
          :href="`/teams/${teamId}/teams_administration`"
        >
          {{ $t('teams.manage') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['team']),
    teams() {
      return this.team.teams;
    },
    currentTeamId() {
      return this.team.teamId;
    },
    adminTeamsIds() {
      return this.$store.state.user.adminTeamsIds;
    },
  },
};
</script>
