import { schema } from 'normalizr';

const reaction = new schema.Entity('reactions');

const comment = new schema.Entity('comments', {
  reactions: [reaction],
});

const announcement = new schema.Entity('announcements', {
  comments: [comment],
  reactions: [reaction],
});

const section = new schema.Entity('sections', {
  announcements: [announcement],
});

const user = new schema.Entity('users');

const documentConfiguration = new schema.Entity('documentConfiguration');

const document = new schema.Entity('documents', {
  sections: [section],
  users: [user],
  documentConfiguration,
});

export default {
  comment,
  announcement,
  section,
  document,
};
