import debounce from 'lodash/debounce';

import 'vue-multiselect/dist/vue-multiselect.css';
import 'floating-vue/dist/style.css';

import { createApp, provide, ref } from 'vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { VueQueryPlugin } from 'vue-query';
import Notifications from '@kyvg/vue3-notification';
import ActionCableVue from 'actioncable-vue';
import InlineSvg from 'vue-inline-svg';
import { ObserveVisibility } from 'vue-observe-visibility';
import { camelizeKeys } from 'humps';
import FloatingVue from 'floating-vue';
import vClickOutside from 'click-outside-vue3';
import VueZoomer from 'vue-zoomer';
import { Form } from 'vee-validate';

import './config/yup';
import i18n from './plugins/i18n';
import notification from './plugins/notification';
import store from './store';
import router from './router';

import Meeting from './views/meeting.vue';
import DocumentsDeprecated from './views/documents-deprecated.vue';
import Sessions from './views/sessions.vue';
import SignUp from './views/sign-up.vue';
import SignIn from './views/sign-in.vue';
import ForgotPassword from './views/forgot-password.vue';
import EditPassword from './views/edit-password.vue';
import EditDocument from './views/edit-document.vue';
import LandingPage from './views/landing-page.vue';
import DocumentTemplates from './views/document-templates.vue';
import NoTeams from './views/no-teams.vue';
import NewTemplate from './views/new-template.vue';
import EditTemplate from './views/edit-template.vue';
import Users from './views/users.vue';
import DocumentTemplate from './views/document-template.vue';

import MuteInput from './components/shared/mute-input.vue';
import MuteVvInput from './components/shared/vee-validate/mute-vv-input.vue';
import MuteButton from './components/shared/mute-button.vue';
import MuteCtaButton from './components/shared/mute-cta-button.vue';
import IconButton from './components/shared/icon-button.vue';
import MuteDivider from './components/shared/mute-divider.vue';
import MuteCheckbox from './components/shared/mute-checkbox.vue';
import MuteVvCheckbox from './components/shared/vee-validate/mute-vv-checkbox.vue';
import MuteRadio from './components/shared/mute-radio.vue';
import MuteVvRadio from './components/shared/vee-validate/mute-vv-radio.vue';
import MuteSwitch from './components/shared/mute-switch.vue';
import MuteMultiselect from './components/shared/mute-multiselect.vue';
import MuteVvMultiselect from './components/shared/vee-validate/mute-vv-multiselect.vue';
import MuteSelect from './components/shared/mute-select.vue';
import MuteVvSelect from './components/shared/vee-validate/mute-vv-select.vue';
import MuteAvatar from './components/shared/mute-avatar.vue';
import MuteChip from './components/shared/mute-chip.vue';
import MuteLogo from './components/shared/mute-logo.vue';
import MuteTag from './components/shared/mute-tag.vue';
import MuteBanner from './components/shared/mute-banner.vue';
import MuteNotification from './components/shared/mute-notification.vue';
import MutePill from './components/shared/mute-pill.vue';
import DatePicker from './components/shared/date-picker.vue';
import NavBar from './components/shared/nav-bar.vue';
import FlashMessage from './components/shared/flash.vue';
import BaseModal from './components/shared/base-modal.vue';
import { initializeAmplitude } from './helpers/amplitude';

// new design system components
import BaseInput from './components/design-system/base-input.vue';
import BaseSelect from './components/design-system/base-select.vue';
import BaseFileInput from './components/design-system/base-file-input.vue';
import BaseVvFileInput from './components/design-system/base-vv-file-input.vue';
import BaseVvInput from './components/design-system/base-vv-input.vue';
import BaseVvSelect from './components/design-system/base-vv-select.vue';
import BaseButton from './components/design-system/base-button.vue';
import BaseCheckbox from './components/design-system/base-checkbox.vue';
import BaseVvCheckbox from './components/design-system/base-vv-checkbox.vue';
import BaseRadio from './components/design-system/base-radio.vue';
import BaseVvRadio from './components/design-system/base-vv-radio.vue';
import BaseToggle from './components/design-system/base-toggle.vue';
import BaseVvToggle from './components/design-system/base-vv-toggle.vue';
import TheNavbar from './components/design-system/the-navbar.vue';
import TheMeetingNavbar from './components/design-system/the-meeting-navbar.vue';
import TheSearchbar from './components/design-system/the-searchbar.vue';
import TheSidebar from './components/design-system/the-sidebar.vue';
import Documents from './components/design-system/documents.vue';
import Integrations from './views/integrations.vue';

import BackButton from './components/back-button.vue';

import './css/application.css';
import './../assets/stylesheets/tooltip.scss';

const DEBOUNCE_TIMER = 50;

window.addEventListener('resize',
  debounce(() => {
    store.dispatch('setWindowWidth', window.innerWidth);
  }, DEBOUNCE_TIMER));
// eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
  initializeAmplitude();

  const app = createApp({
    components: {
      Meeting,
      DocumentsDeprecated,
      Sessions,
      SignUp,
      SignIn,
      ForgotPassword,
      EditDocument,
      EditPassword,
      LandingPage,
      DocumentTemplates,
      NoTeams,
      NewTemplate,
      EditTemplate,
      Users,
      NavBar,
      TheNavbar,
      TheMeetingNavbar,
      FlashMessage,
      TheSidebar,
      DocumentTemplate,
      Documents,
      Integrations,
    },
    setup() {
      const breakpoints = useBreakpoints(breakpointsTailwind);
      const largerThanLg = breakpoints.greater('lg');
      const isSidebarOpen = ref(largerThanLg.value);
      provide('largerThanLg', largerThanLg);
      provide('isSidebarOpen', isSidebarOpen);
      provide('toggleSidebar', () => {
        isSidebarOpen.value = !isSidebarOpen.value;
      });
    },
  });

  app.use(store);
  app.use(router);
  app.use(i18n);
  app.use(notification, { store });
  app.use(ActionCableVue, {
    connectionUrl: '/cable',
    connectImmediately: true,
    store,
  });
  app.use(Notifications);
  app.use(FloatingVue);
  app.use(vClickOutside);
  app.use(VueZoomer);
  app.use(VueQueryPlugin);

  app.component('InlineSvg', InlineSvg)
    .component('TheSearchbar', TheSearchbar)
    .component('MuteInput', MuteInput)
    .component('MuteVvInput', MuteVvInput)
    .component('MuteButton', MuteButton)
    .component('MuteCtaButton', MuteCtaButton)
    .component('IconButton', IconButton)
    .component('MuteDivider', MuteDivider)
    .component('MuteCheckbox', MuteCheckbox)
    .component('MuteVvCheckbox', MuteVvCheckbox)
    .component('MuteRadio', MuteRadio)
    .component('MuteVvRadio', MuteVvRadio)
    .component('MuteSwitch', MuteSwitch)
    .component('MuteMultiselect', MuteMultiselect)
    .component('MuteVvMultiselect', MuteVvMultiselect)
    .component('MuteChip', MuteChip)
    .component('MuteLogo', MuteLogo)
    .component('MuteTag', MuteTag)
    .component('MuteBanner', MuteBanner)
    .component('MuteNotification', MuteNotification)
    .component('MutePill', MutePill)
    .component('MuteAvatar', MuteAvatar)
    .component('MuteSelect', MuteSelect)
    .component('MuteVvSelect', MuteVvSelect)
    .component('DatePicker', DatePicker)
    .component('VForm', Form)
    .component('BaseModal', BaseModal)
    .component('BaseSelect', BaseSelect)
    .component('BaseInput', BaseInput)
    .component('BaseFileInput', BaseFileInput)
    .component('BaseVvFileInput', BaseVvFileInput)
    .component('BaseVvSelect', BaseVvSelect)
    .component('BaseVvInput', BaseVvInput)
    .component('BaseButton', BaseButton)
    .component('BaseCheckbox', BaseCheckbox)
    .component('BaseVvCheckbox', BaseVvCheckbox)
    .component('BaseRadio', BaseRadio)
    .component('BaseVvRadio', BaseVvRadio)
    .component('BaseToggle', BaseToggle)
    .component('BaseVvToggle', BaseVvToggle)
    .component('BackButton', BackButton);

  app.directive('observe-visibility', ObserveVisibility);

  app.config.globalProperties.$filters = {
    camelizeKeys,
  };

  app.mount('#vue-app');

  return app;
});
