<template>
  <div class="space-y-2 border-b pb-2">
    <toggle-button
      id="notificationToggler"
      class="mb-4"
      :text="$t('meetings.config.notifications')"
      :model-value="notificationsAllowed"
      @update:model-value="allowNotifications"
    />
    <toggle-button
      id="unreadMessageToggler"
      class="mb-4"
      :text="$t('meetings.config.unreadMessages')"
      :model-value="unreadMessagesAllowed"
      @update:model-value="toggleUnreadMessages"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { logAmplitudeEvent } from '../../helpers/amplitude';
import ToggleButton from '../shared/toggle-button.vue';

export default {
  components: { ToggleButton },
  computed: mapState({
    document: (state) => state.document,
    teamId: (state) => state.team.teamId,
    notificationsAllowed: (state) => state.document.notificationsAllowed,
    unreadMessagesAllowed: (state) => state.document.allowUnreadMessages,
  }),
  methods: {
    ...mapMutations(['setNotificationsAllowed']),
    toggleUnreadMessages(newValue) {
      this.$store.dispatch('toggleUnreadMessages', newValue);
    },
    allowNotifications(newValue) {
      this.notification = newValue;
      this.setNotificationsAllowed(newValue);
      if (!newValue) {
        logAmplitudeEvent('silence-meeting', {
          teamId: this.teamId,
          documentId: this.document.document.id,
        });
      }
    },
  },
};
</script>
