<template>
  <session-wrapper
    :show-back-button="false"
    :header="$t('sessions.header')"
  >
    <template #content>
      <p class="mb-6 ">
        {{ $t('sessions.start') }}
      </p>
      <mute-cta-button
        tag="a"
        class="w-full mb-6"
        href="/users/sign_in"
        variant="purple"
      >
        {{ $t('sessions.login') }}
      </mute-cta-button>
      <mute-cta-button
        tag="a"
        class="w-full mb-6"
        href="/users/sign_up"
        variant="green"
      >
        {{ $t('sessions.signUp.signUp') }}
      </mute-cta-button>
      <a
        href="/users/password/new"
        class="self-center text-sm text-gray-600 underline"
      >
        {{ $t('sessions.forgotPassword') }}
      </a>
    </template>
    <a
      href="/users/password/new"
      class="flex justify-center text-sm text-blue-500 underline hover:text-blue-600"
    >
      {{ $t('sessions.forgotPassword') }}
    </a>
  </session-wrapper>
</template>

<script>
import sessionWrapper from '../components/session/session-wrapper.vue';

export default {
  components: { sessionWrapper },
};
</script>
