import union from 'lodash/union';
import teamsApi from '../api/team';
import tagsApi from '../api/tags';

const initialState = {
  teamId: null,
  teams: {},
  tagList: [],
};

const actions = {
  setTeam({ commit, dispatch }, teamId) {
    commit('setTeamId', { id: teamId });
    dispatch('getTagList');
  },
  async getTagList({ commit, state }) {
    const tagList = await tagsApi.index(state.teamId);
    commit('setTagList', tagList.data['actsAsTaggableOn/tags'].map(tag => tag.name));
  },
  setTeams({ commit, dispatch }) {
    teamsApi.getTeams().then(({ data }) => {
      const teams = Object.assign({}, ...data.teams.map((team) => ({ [team.id]: { ...team } })));
      commit('setTeams', teams);
    });
    dispatch('getTagList');
  },
  setTagList({ commit, state }, newTags) {
    commit('setTagList', union(newTags, state.tagList));
  },
};

const mutations = {
  setTeamId(state, payload) {
    state.teamId = payload.id;
  },
  setTeams(state, payload) {
    state.teams = payload;
  },
  setTagList(state, payload) {
    state.tagList = payload;
  },
};

export default {
  state: { ...initialState },
  actions,
  mutations,
};
