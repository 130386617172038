import resolveConfig from 'tailwindcss/resolveConfig';
import { normalize } from 'normalizr';
import { pickBy } from 'lodash';
import schemas from '../../schemas';

import documentApi from '../../api/documents';
import tailwindConfig from '../../../../tailwind.config.js';
import { logCheckOldMuteEvent } from '../../helpers/amplitude';
import isUserMentionedInHtml from '../../helpers/isUserMentionedInHtml';
import { getSectionIdFromAnnouncementId } from './helpers';

const initialState = {
  document: null,
  sections: null,
  windowWidth: window.innerWidth,
  onSearchPage: false,
};

const fullConfig = resolveConfig(tailwindConfig);

const getters = {
  hasLargeScreen(state) {
    return state.windowWidth >= parseInt(fullConfig.theme.screens.lg.replace('px', ''), 10);
  },
  sections(state, documentGetters) {
    if (documentGetters.anyFilterToggled) {
      return pickBy(documentGetters.filteredSectionAnnouncements,
        (section) => section.announcements.length > 0);
    }

    return state.sections;
  },
  getSectionIdByAnnouncementId: (state, _getters) => (announcementId) => {
    const sections = _getters.sections;

    return getSectionIdFromAnnouncementId(sections, announcementId);
  },
  isUsernameInMessage: (_state, _getters, rootState) => (
    (message) => isUserMentionedInHtml(message, rootState.user.username)
  ),
};

const actions = {
  getDocument({ commit, state, rootState }) {
    return documentApi.getDocument(state.document.id)
      .then(({ data }) => {
        const response = data.document;
        const normalizedData = normalize(response, schemas.document);
        commit('setDocument', normalizedData.entities.documents[state.document.id]);
        commit('setSections', normalizedData.entities.sections);
        commit('setAnnouncements', normalizedData.entities.announcements);
        commit('setComments', normalizedData.entities.comments);
        commit('setReactions', normalizedData.entities.reactions);
        commit('setUsers', normalizedData.entities.users);
        commit('setDocumentConfiguration',
          normalizedData.entities.documentConfiguration[
            normalizedData.entities.documents[state.document.id].documentConfiguration
          ],
        );
        logCheckOldMuteEvent(response, rootState.team.teamId);
      });
  },
  setDocumentId({ commit }, documentId) {
    commit('setDocument', { id: documentId });
  },
  createSection({ commit }, section) {
    commit('updateSection', section);
  },
  setWindowWidth({ commit }, payload) {
    commit('setWindowWidth', payload);
  },
  toggleOnSearchPage({ commit }, newValue) {
    commit('setOnSearchPage', newValue);
  },
};

const mutations = {
  setDocument(state, payload) {
    state.document = payload;
  },
  setSections(state, payload) {
    state.sections = payload;
  },
  updateSection(state, payload) {
    state.sections[payload.id] = payload;
  },
  setWindowWidth(state, payload) {
    state.windowWidth = payload;
  },
  setOnSearchPage(state, newValue) {
    state.onSearchPage = newValue;
  },
};

export default {
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
