<template>
  <base-modal
    :title="$t('templates.preview.delete.message')"
    @close="beforeClose"
  >
    <backend-errors
      v-if="errors.length > 0"
      :errors="errors"
    >
      {{ $t('templates.preview.delete.error') }}
    </backend-errors>
    <p class="mb-4">
      {{ $t('templates.preview.delete.confirm') }}
    </p>
    <div class="flex justify-end">
      <mute-button
        id="destroy-document-modal-close-button"
        variant="green"
        outlined
        class="mr-2 w-auto"
        @click="hideDestroyTemplateModal"
      >
        {{ $t('templates.preview.delete.no') }}
      </mute-button>
      <mute-button
        id="destroy-document-modal-submit-button"
        :disabled="loading"
        variant="red"
        @click="destroyTemplate"
      >
        {{ $t('templates.preview.delete.yes') }}
      </mute-button>
    </div>
  </base-modal>
</template>

<script>
import templatesApi from '../../api/document_templates';
import backendErrors from '../shared/backend-errors.vue';

export default {
  components: { backendErrors },
  props: { template: { type: Object, required: true } },
  emits: ['close', 'after-destroy'],
  data() {
    return {
      errors: [],
      loading: false,
    };
  },
  methods: {
    hideDestroyTemplateModal() {
      this.$emit('close');
    },
    async destroyTemplate() {
      this.loading = true;

      try {
        await templatesApi.destroyTemplate(this.template.id);
        this.hideDestroyTemplateModal();
        this.$emit('after-destroy');
      } catch (error) {
        if (error.details) {
          this.errors = error.details;
        }
      }

      this.loading = false;
    },
    beforeClose() {
      this.errors = [];
      this.hideDestroyTemplateModal();
    },
  },
};
</script>
