<template>
  <mute-input
    v-bind="$attrs"
    v-model="value"
    :name="name"
    :has-error="hasError"
    :error-message="errorMessage"
    @blur="handleBlur"
  />
</template>

<script>
import { toRef } from 'vue';
import { useField } from 'vee-validate';
import MuteInput from '../mute-input.vue';
import yupI18nErrorMessage from '../../../helpers/yup';

export default {
  components: { MuteInput },
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
  },

  setup(props) {
    const { value, meta, errorMessage, handleBlur } = useField(toRef(props, 'name'), undefined, {
      validateOnMount: true,
    });

    return { value, meta, errorKey: errorMessage, handleBlur };
  },
  computed: {
    errorMessage() {
      return yupI18nErrorMessage(this.errorKey);
    },
    hasError() {
      return this.errorKey && this.meta.touched;
    },
  },
};
</script>
