<template>
  <a
    :href="href"
    class="flex items-center self-center text-green-500 border-b border-transparent hover:border-green-500"
  >
    <span class="leading-none">
      <slot />
    </span>
    <inline-svg
      :src="require('../../../assets/images/icons/right-arrow.svg')"
      class="fill-current"
    />
  </a>
</template>

<script>
export default {
  props: {
    href: { type: String, required: true },
  },
};
</script>
