<template>
  <tr>
    <td
      v-if="isCurrentUserAdmin"
      class="p-1 text-center sm:p-3"
    >
      <div class="flex justify-center">
        <mute-checkbox
          :id="`member-${member.id}-selected`"
          :model-value="member.isSelected"
          @update:model-value="toggleSelected"
        />
      </div>
    </td>
    <td class="p-1 sm:p-3">
      <div class="flex flex-row items-center space-x-2 w-28 sm:w-auto sm:space-x-4">
        <mute-avatar
          class="block sm:hidden"
          :src="member.avatar"
          size="small"
        />
        <mute-avatar
          class="hidden sm:block"
          :src="member.avatar"
          size="medium"
        />
        <div class="flex flex-col grow min-w-0 whitespace-nowrap">
          <p class="overflow-hidden text-sm text-green-400 text-ellipsis">
            @{{ member.username }}
          </p>
          <p class="overflow-hidden text-sm text-ellipsis">
            {{ member.email }}
          </p>
        </div>
      </div>
    </td>
    <td class="p-1 text-center sm:p-3">
      <div class="flex justify-center">
        <mute-switch
          v-if="isCurrentUserAdmin"
          :id="toggleButtonId"
          :model-value="isAdmin"
          @update:model-value="toggleAdmin"
        />
        <inline-svg
          v-else
          :src="require(`assets/images/icons/${isAdmin ? 'check' : 'close'}-icon.svg`)"
          class="h-4 w-4"
          :class="isAdmin ? 'fill-green-500' : 'fill-red-500'"
        />
      </div>
    </td>
    <td
      v-if="isCurrentUserAdmin"
      class="p-1 sm:p-3"
    >
      <div class="flex justify-center">
        <mute-button
          variant="red"
          outlined
          icon-file-name="delete.svg"
          class="hidden sm:block"
          @click="deleteMember"
        />
        <mute-button
          variant="red"
          outlined
          size="sm"
          icon-file-name="delete.svg"
          class="block sm:hidden"
          @click="deleteMember"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import TeamApi from '../../api/team';

export default {
  props: {
    member: { type: Object, required: true },
    team: { type: Object, required: true },
    currentUser: { type: Object, required: true },
  },
  emits: ['toggled-member-selected', 'delete-member'],
  data() {
    return {
      isAdmin: this.member.isAdmin,
    };
  },
  computed: {
    toggleButtonId() {
      return `toggle-button-${this.member.id}`;
    },
    isCurrentUserAdmin() {
      return this.team.adminIds.includes(this.currentUser.id);
    },
  },
  methods: {
    toggleSelected() {
      this.$emit('toggled-member-selected', this.member);
    },
    async toggleAdmin() {
      if (this.isAdmin) {
        await TeamApi.removeRoleFromUser(this.team.id, this.member.id, 'admin');
        if (this.member.id === this.currentUser.id) window.location.reload();
      } else {
        await TeamApi.addRoleToUser(this.team.id, this.member.id, 'admin');
      }
      this.isAdmin = !this.isAdmin;
    },
    deleteMember() {
      this.$emit('delete-member', this.member);
    },
  },
};
</script>
