<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <div class="flex flex-col items-center flex-1 p-5 overflow-scroll">
      <div class="flex flex-col w-full h-full lg:w-4/5 p-8">
        <backend-errors
          v-if="errors.length > 0"
          :errors="errors"
        >
          {{ $t('templates.form.error') }}
        </backend-errors>
        <h2 class="text-xl text-slate-600">
          {{ $t('templates.topbar.edit') }}
        </h2>
        <div class="h-auto bg-white rounded-lg mt-8">
          <template-form
            class="flex-1"
            :template="template"
            :base-document="baseDocument"
            :team-members="teamMembers"
            :masters="masters"
            :user="user"
            @submit="editTemplate"
          />
        </div>
      </div>
    </div>
    <loading-overlay v-if="loading" />
  </div>
</template>

<script>
import LoadingOverlay from '../components/shared/loading-overlay.vue';
import TemplateForm from '../components/templates/template-form.vue';
import TopBar from '../components/templates/top-bar.vue';
import DocumentTemplateApi from '../api/document_templates';
import BackendErrors from '../components/shared/backend-errors.vue';

export default {
  components: { TopBar, TemplateForm, LoadingOverlay, BackendErrors },
  props: {
    template: {
      type: Object,
      default: null,
    },
    baseDocument: {
      type: Object,
      default: null,
    },
    teamMembers: {
      type: Array,
      default: null,
    },
    masters: {
      type: Array,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { errors: [], loading: false };
  },
  computed: {
    teamId() {
      return this.baseDocument.teamId;
    },
  },
  methods: {
    async editTemplate(templateParams) {
      this.loading = true;
      try {
        await DocumentTemplateApi.editTemplate(this.template.id, templateParams.api);
        window.location.replace(`/teams/${this.teamId}/document_templates`);
      } catch (error) {
        if (error.details) {
          this.errors = error.details;
        }
      }
      this.loading = false;
    },
    isSectionModified(section, originalSection) {
      return originalSection.title !== section.title || originalSection.order !== section.order;
    },
  },
};
</script>
