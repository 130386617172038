<template>
  <div class="space-y-4 mb-8">
    <mute-vv-input
      id="name-input"
      type="text"
      :label="$t('templates.form.name')"
      class="col-span-full"
      name="name"
    />
    <recurrent-input
      :is-active="values.recurrence.isActive"
      name="recurrence"
    />
    <document-form
      class="col-span-full"
      :document="baseDocument"
      :team-members="teamMembers"
      :masters="masters"
      :user="user"
      :external-validation="!meta.valid"
      is-for-template
      @submit="submitAction"
    />
  </div>
</template>

<script>
import changedEditedSections from 'helpers/changedEditedSections';
import useTemplateForm from '../../composables/useTemplateForm';
import DocumentForm from '../documents/document-form.vue';
import RecurrentInput from './recurrent-input.vue';

export default {
  components: { DocumentForm, RecurrentInput },
  props: {
    template: {
      type: Object,
      default: null,
    },
    baseDocument: {
      type: Object,
      default: null,
    },
    teamMembers: {
      type: Array,
      default: null,
    },
    masters: {
      type: Array,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ['submit'],
  setup(props) {
    const { meta, values } = useTemplateForm({ template: props.template });

    return { meta, values };
  },
  methods: {
    submitAction(baseDocumentForm) {
      const params = {
        api: {
          template: {
            name: this.values.name, recurrent: this.values.recurrence.isActive, hours: this.values.recurrence.hour,
            weeks: parseInt(this.values.recurrence.weeks, 10), weekDay: parseInt(this.values.recurrence.weekDay, 10),
            timezoneOffset: this.timeZoneOffset(),
          },
          baseDocument: this.baseDocumentParams(baseDocumentForm),
        },
      };
      this.$emit('submit', params);
    },
    baseDocumentParams({ datetime, videoUrl, participants, mastersOfCeremonies, instructions,
      sections, documentConfigurationAttributes, access }) {
      const membersIds = participants.map(({ id }) => (id));
      const { teamId } = this.baseDocument;
      const title = this.values.name;
      const sectionsAttributes = this.formattedSections(sections);

      return {
        title, videoUrl, membersIds, datetime, mastersOfCeremonies, instructions, teamId,
        sectionsAttributes, documentConfigurationAttributes, access,
      };
    },
    formattedSections(editedSections) {
      if (!this.baseDocument.id) {
        return editedSections.map((section) => ({ title: section.title, order: section.order }));
      }

      return changedEditedSections(editedSections, this.baseDocument.sections);
    },
    timeZoneOffset() {
      return new Date().getTimezoneOffset();
    },

  },
};
</script>
