<template>
  <button
    class="w-10 h-10 col-span-1 transition-colors duration-150 ease-in-out rounded shadow justify-self-end focus:outline-none"
    :class="colorClasses"
  >
    <inline-svg
      :src="require(`assets/images/icons/${iconFileName}`)"
      class="grow-0 mx-auto fill-current"
    />
  </button>
</template>

<script>
export default {
  props: {
    variant: { type: String, default: 'purple' },
    iconFileName: { type: String, default: null },
    outlined: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    colorClasses() {
      const classes = {
        purple: 'text-white bg-purple-500 hover:bg-purple-600',
        green: 'text-white bg-green-500 hover:bg-green-600',
        red: 'text-white bg-red-500 hover:bg-red-600',
        white: 'text-black bg-white hover:bg-gray-100',
        disabled: 'text-gray-300 bg-white cursor-default',
      };

      const outlinedClasses = {
        purple: 'text-purple-500 bg-white border border-purple-500 hover:bg-gray-100',
        green: 'text-green-500 bg-white border border-green-500 hover:bg-gray-100',
        white: 'text-black bg-white hover:bg-gray-100 border border-black',
        red: 'text-red-500 bg-white border border-red-500 hover:bg-gray-100',
        disabled: 'text-gray-300 bg-white border border-gray-300 cursor-default',
      };

      const variant = this.disabled ? 'disabled' : this.variant;

      return this.outlined ? outlinedClasses[variant] : classes[variant];
    },
  },
};
</script>
