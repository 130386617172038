<template>
  <vue-tags-input
    v-model="text"
    :tags="modelValue"
    :add-on-key="[13, ',']"
    :allow-edit-tags="true"
    @tags-changed="(newTags) => $emit('update:modelValue', newTags)"
  />
</template>

<script>
import VueTagsInput from '@sipec/vue3-tags-input';

export default {
  components: { VueTagsInput },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return { text: '' };
  },
};
</script>

<style lang="scss" scoped>
 .vue-tags-input::v-deep {
    @apply w-full max-w-none;
    .ti-input {
      @apply rounded-md;
    }

    .ti-tag {
      @apply rounded-md text-purple-500 bg-purple-100;
    }
  }
</style>
