function updateDocumentConfiguration(object, { getters }) {
  return Object.entries(object)
    .filter(([configName, newValue]) => {
      const getter = getters[configName];
      if (!getter) return false;

      const oldValue = getter();

      return oldValue !== newValue;
    })
    .map(([configName, newValue]) => ({
      data: {
        changedConfiguration: configName,
        enabled: newValue,
        type: 'document-configuration',
      },
    }));
}

const documentConfigurationNotifications = {
  updateDocumentConfiguration,
};

export default documentConfigurationNotifications;
