<template>
  <base-modal
    :title="$t('teams.administration.confirmDeletion.title')"
    @close="$emit('close')"
  >
    <div class="mb-4">
      <i18n-t
        :keypath="amountToDelete > 1 ?
          'teams.administration.confirmDeletion.multiple' :
          'teams.administration.confirmDeletion.single'"
        tag="p"
        scope="global"
      >
        <template #value>
          <span class="text-green-400">{{ amountToDelete > 1 ? amountToDelete : `@${username}` }}</span>
        </template>
      </i18n-t>
      <p>
        {{ $t('teams.administration.confirmDeletion.message') }}
      </p>
    </div>
    <div class="flex justify-end px-4">
      <mute-button
        class="h-12 mr-4"
        outlined
        @click="closeDeletionModal"
      >
        {{ $t('teams.administration.confirmDeletion.back') }}
      </mute-button>
      <mute-button
        class="h-12"
        @click="confirmDeletion"
      >
        {{ $t('teams.administration.confirmDeletion.continue') }}
      </mute-button>
    </div>
  </base-modal>
</template>

<script>

export default {
  props: {
    membersToDelete: { type: Array, required: false, default: null },
  },
  emits: ['close-deletion-modal', 'delete-members', 'close'],
  computed: {
    amountToDelete() {
      return this.membersToDelete ? Object.keys(this.membersToDelete).length : 0;
    },
    username() {
      return this.amountToDelete === 1 ? this.firstMember.username : '';
    },
    firstMember() {
      return this.membersToDelete ? this.membersToDelete[Object.keys(this.membersToDelete)[0]] : null;
    },
  },

  methods: {
    closeDeletionModal() {
      this.$emit('close-deletion-modal');
    },
    confirmDeletion() {
      this.$emit('delete-members', this.membersToDelete);
    },
  },
};
</script>
