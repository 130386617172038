<template>
  <div
    v-if="userToken"
    class="flex items-center space-x-4"
    :class="!upvotyLoaded && 'hidden'"
  >
    <dropdown
      class="z-30"
      box-class="overflow-auto w-96 h-96"
      @dropdown-open="widgetVisible = true"
    >
      <template #button>
        <mute-button
          icon-file-name="feedback.svg"
          variant="green"
          class="hidden sm:flex"
        >
          {{ $t('feedback.navBarButtonMessage') }}
        </mute-button>
        <mute-button
          icon-file-name="feedback.svg"
          variant="green"
          class="flex sm:hidden"
          size="sm"
        >
          {{ $t('feedback.navBarButtonMessage') }}
        </mute-button>
      </template>
      <template #menu>
        <upvoty-frame
          class="flex-1"
          :user-token="userToken"
          @initialized="upvotyLoaded = true"
        />
        <a
          :href="boardUrl"
          class="text-xs text-center text-gray-400 underline"
          target="blank"
        >
          {{ $t('feedback.goToBoard') }}
        </a>
      </template>
    </dropdown>
  </div>
</template>

<script>
import Dropdown from '../shared/dropdown.vue';
import UpvotyFrame from './upvoty-frame.vue';

const BASE_URL = process.env.UPVOTY_BASE_URL;
const BOARD_HASH = process.env.UPVOTY_BOARD_HASH;

export default {
  components: { UpvotyFrame, Dropdown },
  props: {
    userToken: { type: String, default: null },
  },
  data() {
    return {
      upvotyLoaded: false,
      widgetVisible: false,
    };
  },
  computed: {
    boardUrl() {
      return `https://${BASE_URL}/b/${BOARD_HASH}`;
    },
  },
  watch: {
    widgetVisible() {
      if (this.widgetVisible) window.upvoty.sendMessage({ event: 'resized' });
    },
  },
};
</script>
