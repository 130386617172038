<template>
  <div class="fixed z-20 flex flex-col w-screen h-full pb-20 bg-slate-50 top-14">
    <div class="flex items-center p-2 bg-white">
      <button @click="$emit('hide-mobile-modal')">
        <inline-svg
          :src="require('assets/images/icons/back-arrow-green.svg')"
        />
      </button>
    </div>
    <topics-bar
      class="mb-auto"
      @hide-mobile-modal="$emit('hide-mobile-modal')"
    />
    <nav-actions
      class="mt-10"
    />
  </div>
</template>

<script>
import NavActions from '../design-system/nav-actions.vue';
import TopicsBar from './topics-bar.vue';

export default {
  components: {
    NavActions,
    TopicsBar,
  },
  props: {
    documentInstructions: {
      type: Object,
      default: null,
    },
    documentId: {
      type: Number,
      default: 0,
    },
    videoUrl: {
      type: String,
      default: null,
    },
  },
  emits: ['hide-mobile-modal'],
};
</script>
