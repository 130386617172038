import merge from 'lodash/merge';
import document from './document';
import notifications from './notifications';
import unreadMessages from './unread-messages';
import documentConfiguration from './document-configuration';
import hidden from './hidden';
import filters from './filters';
import messages from './messages';
import users from './users';

const modules = [
  document,
  documentConfiguration,
  unreadMessages,
  notifications,
  hidden,
  filters,
  messages,
  users,
];

export default {
  state: merge({}, ...modules.map(m => m.state)),
  actions: merge({}, ...modules.map(m => m.actions)),
  mutations: merge({}, ...modules.map(m => m.mutations)),
  getters: merge({}, ...modules.map(m => m.getters)),
};
