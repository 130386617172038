import api from './index';

export default {
  getChangelogItems(featuresSeenAt) {
    return api({
      method: 'get',
      url: '/api/internal/changelog_items',
      params: { dateFilter: featuresSeenAt },
    });
  },
};
