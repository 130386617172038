<template>
  <div class="flex-1 overflow-hidden">
    <div class="border-gray-300 mb-4 border-b-2">
      <ul class="flex cursor-pointer">
        <li
          v-for="tabName in Object.keys(tabNames)"
          :key="tabName"
          class="px-3 py-1 md:px-6 md:py-2 rounded-t-lg text-xs md:text-base"
          :class="[isActive(tabName) ? 'bg-purple-500 text-white' : 'bg-gray-200' ]"
          @click="handleTabClick(tabName)"
        >
          {{ tabNames[tabName] }}
        </li>
      </ul>
    </div>
    <div class="overflow-y-auto">
      <div
        v-for="tabName in Object.keys(tabNames)"
        :key="tabName"
        :class="{hidden: !isActive(tabName)}"
      >
        <slot
          v-if="isActive(tabName)"
          :name="tabName"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabNames: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: '',
    };
  },
  mounted() {
    this.activeTab = Object.keys(this.tabNames)[0];
  },
  methods: {
    isActive(tabName) {
      return tabName === this.activeTab;
    },
    handleTabClick(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>
