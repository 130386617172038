<template>
  <button
    class="flex items-center transition-colors duration-150 ease-in-out rounded-full focus:outline-none"
    :class="[
      sizeClasses,
      inactive && 'text-black bg-gray-300 cursor-default',
      !inactive && colorClasses,
    ]"
  >
    <inline-svg
      v-if="iconFileName"
      :class="svgClasses"
      class="fill-current"
      :src="require(`assets/images/icons/${iconFileName}`)"
    />
    <span>
      <slot />
    </span>

    <inline-svg
      v-if="rightIconFileName"
      :class="svgClasses"
      class="ml-1 fill-current"
      :src="require(`assets/images/icons/${rightIconFileName}`)"
    />
  </button>
</template>

<script>
export default {
  props: {
    variant: { type: String, default: 'purple' },
    iconFileName: { type: String, default: null },
    rightIconFileName: { type: String, default: null },
    inactive: { type: Boolean, default: false },
    size: { type: String, default: 'medium' },
  },
  computed: {
    disabled() {
      return this.$attrs.disabled;
    },
    colorClasses() {
      const classes = {
        purple: 'text-white bg-purple-500 hover:bg-purple-600',
        green: 'text-white bg-green-500 hover:bg-green-600',
        red: 'text-white bg-red-500 hover:bg-red-600',
        amber: 'text-amber-600 bg-amber-100 hover:bg-amber-200',
      };

      const variant = this.disabled ? 'inactive' : this.variant;

      return classes[variant];
    },

    sizeClasses() {
      const classes = {
        'extra-small': 'h-5 py-0.5 px-1',
        'small': 'h-6 py-1 px-2',
        'medium': 'h-7 py-1.5 px-3',
        'large': 'h-9 py-2 px-3',
      };

      return classes[this.size];
    },

    svgClasses() {
      const classes = {
        'extra-small': 'h-3 w-3',
        'small': 'h-3 w-3',
        'medium': 'h-3 w-3',
        'large': 'h-4 w-4',
      };

      return classes[this.size];
    },
  },
};
</script>
