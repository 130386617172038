<template>
  <session-wrapper
    :show-back-button="true"
    back-button-href="/sessions"
    :header="$t('sessions.signUp.signUp')"
  >
    <template #content>
      <slot name="errors" />
      <sign-up-user-form :user="user" />
    </template>
    <template #footer>
      <session-footer-link href="/users/sign_in">
        {{ $t('sessions.login') }}
      </session-footer-link>
    </template>
  </session-wrapper>
</template>

<script>
import SessionFooterLink from '../components/session/session-footer-link.vue';
import sessionWrapper from '../components/session/session-wrapper.vue';
import SignUpUserForm from '../components/session/sign-up-user-form.vue';

export default {
  components: { sessionWrapper, SignUpUserForm, SessionFooterLink },
  props: {
    user: { type: Object, required: true },
  },
};
</script>
