<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <div class="container h-full p-4 md:px-16 lg:px-24 md:py-8 mx-auto overflow-hidden">
      <div class="flex flex-col h-full col-end-7 p-3 space-y-4 overflow-hidden bg-white rounded-lg sm:p-4">
        <div class="flex justify-between items-center">
          <p class="text-3xl text-purple-500">
            {{ team.name }}
          </p>
          <invite-modal :current-team="team" />
        </div>
        <mute-divider width="w-full" />
        <mute-input
          v-model="search"
          size="small"
          :label="$t('teams.administration.search')"
          class="block sm:hidden"
        />
        <div class="flex flex-col h-full overflow-auto">
          <table class="border-collapse">
            <thead class="bg-gray-100">
              <tr>
                <th
                  v-if="isCurrentUserAdmin"
                  class="p-2 sm:p-3"
                >
                  <div class="flex justify-center">
                    <mute-checkbox
                      id="all-members-selected"
                      :model-value="allSelected"
                      @update:model-value="massSelectionToggle"
                    />
                  </div>
                </th>
                <th class="p-2 sm:p-3">
                  <mute-input
                    v-model="search"
                    size="small"
                    :label="$t('teams.administration.search')"
                    class="hidden sm:block"
                  />
                  <p class="block text-sm font-normal text-left text-gray-500 sm:hidden">
                    {{ $t('teams.administration.users') }}
                  </p>
                </th>
                <th class="p-2 text-sm font-normal text-gray-500 sm:p-3">
                  {{ $t('teams.administration.admin') }}
                </th>
                <th
                  v-if="isCurrentUserAdmin"
                  class="p-2 text-sm font-normal text-gray-500 sm:p-3"
                >
                  {{ $t('teams.administration.actions') }}
                </th>
              </tr>

              <tr v-if="selectedMembersCount != 0">
                <th />
                <th
                  class="p-2 text-sm font-normal text-left text-gray-500 sm:p-3"
                  colspan="2"
                >
                  {{ $t('teams.administration.selected', {amount: selectedMembersCount}) }}
                </th>
                <th class="p-2 sm:p-3">
                  <div class="flex justify-center">
                    <mute-button
                      :outlined="true"
                      icon-file-name="delete.svg"
                      class="hidden sm:block"
                      @click="deleteSelectedMembers"
                    />
                    <mute-button
                      :outlined="true"
                      icon-file-name="delete.svg"
                      size="sm"
                      class="block sm:hidden"
                      @click="deleteSelectedMembers"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <user-administration-card
                v-for="member in filteredMembers"
                :key="member.id"
                :member="member"
                :team="team"
                :current-user="currentUser"
                @toggled-member-selected="toggleMemberSelected"
                @delete-member="deleteMember"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <delete-users-confirmation-modal
      :members-to-delete="membersToDelete"
      :open="isDeletionModalOpen"
      @close-deletion-modal="closeDeletionModal"
      @delete-members="confirmDeletion"
      @close="closeDeletionModal"
    />
  </div>
</template>

<script>
import { keyBy, mapValues, pickBy } from 'lodash';
import UserAdministrationCard from '../components/teams/user-administration-card.vue';
import DeleteUsersConfirmationModal from '../components/teams/delete-users-confirmation-modal.vue';
import InviteModal from '../components/documents/invite-modal.vue';
import TeamApi from '../api/team';

export default {
  components: { UserAdministrationCard, DeleteUsersConfirmationModal, InviteModal },

  props: {
    currentUser: { type: Object, required: true },
    team: { type: Object, required: true },
    teamMembers: { type: Array, required: true },
  },
  data() {
    const mappedMembers = this.teamMembers.map((member) => ({
      ...member,
      isAdmin: this.team.adminIds.includes(member.id),
      isSelected: false,
      willBeDeleted: false,
    }));

    return {
      members: keyBy(mappedMembers, 'id'),
      search: '',
      isDeletionModalOpen: false,
    };
  },
  computed: {
    isCurrentUserAdmin() {
      return this.team.adminIds.includes(this.currentUser.id);
    },
    selectedMembersCount() {
      return Object.values(this.members).filter((member) => member.isSelected).length;
    },
    filteredMembers() {
      if (!this.search) {
        return Object.values(this.members);
      }

      return Object.values(this.members).filter(member => (
        member.username.toLowerCase().includes(this.search.toLowerCase()) ||
        member.email.toLowerCase().includes(this.search.toLowerCase())
      ));
    },
    allSelected() {
      return this.selectedMembersCount >= Object.keys(this.members).length;
    },
    membersToDelete() {
      return Object.values(this.members).filter(
        m => m.willBeDeleted,
      );
    },
  },
  methods: {
    toggleMemberSelected(member) {
      this.members[member.id].isSelected = !this.members[member.id].isSelected;
      this.members[member.id].willBeDeleted = this.members[member.id].isSelected;
    },

    closeDeletionModal() {
      this.isDeletionModalOpen = false;
    },

    openDeleteModal() {
      this.isDeletionModalOpen = true;
    },

    deleteMember(member) {
      this.unselectDeletion();
      this.members[member.id].willBeDeleted = true;
      this.openDeleteModal();
    },

    deleteSelectedMembers() {
      this.markToDelete();
      this.openDeleteModal();
    },

    unselectMembers() {
      mapValues(this.members, (member) => {
        member.isSelected = false;
      });
    },

    unselectDeletion() {
      mapValues(this.members, (member) => {
        member.willBeDeleted = false;
      });
    },

    massSelectionToggle() {
      const newSelectStatus = !this.allSelected;
      mapValues(this.members, (member) => {
        member.isSelected = newSelectStatus;
      });
    },

    markToDelete() {
      mapValues(this.members, (member) => {
        member.willBeDeleted = member.isSelected;
      });
    },

    removeDeletedMembers() {
      this.members = pickBy(this.members, ({ willBeDeleted }) => !willBeDeleted);
    },

    async confirmDeletion() {
      const body = {
        UsersIds: this.membersToDelete.map(member => member.id),
      };
      await TeamApi.batchDestroyUsersFromTeam(body, this.team.id);
      this.removeDeletedMembers();
      this.closeDeletionModal();
      this.unselectDeletion();
    },
  },
};
</script>
