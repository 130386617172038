<template>
  <footer class="text-white bg-purple-500">
    <div class="container flex flex-col px-8 py-32 mx-auto lg:flex-row">
      <div class="flex flex-col mt-8 items-center order-last lg:order-first lg:items-start lg:mb-0 md:mr-0 lg:mr-16 xl:mr-32">
        <div class="mb-4">
          <inline-svg
            :src="require('assets/images/logo.svg')"
            class="h-12 fill-current"
          />
        </div>
        <p>
          &#169; {{ currentYear }} {{ $t('landing.footer.allRightsReserved') }}
        </p>
      </div>
      <div class="flex justify-between lg:justify-start">
        <div class="flex flex-col mr-8 xl:mr-32">
          <h4 class="mb-4 text-2xl font-bold">
            Mute
          </h4>
          <a
            class="mb-2 hover:underline underline opacity-70 hover:opacity-100"
            href="https://platan.us/team"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('landing.footer.mute.team') }}
          </a>
          <a
            class="mb-2 hover:underline underline opacity-70 hover:opacity-100"
            href="/users/sign_in"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('landing.footer.mute.signIn') }}
          </a>
        </div>
        <div class="flex flex-col mr-8 xl:mr-32">
          <h4 class="mb-4 text-2xl font-bold">
            Platanus
          </h4>
          <a
            class="mb-2 hover:underline underline opacity-70 hover:opacity-100"
            href="https://platan.us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Platanus
          </a>
          <a
            class="mb-2 hover:underline underline opacity-70 hover:opacity-100"
            href="https://plata.news/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </div>
        <div class="flex flex-col mr-8 xl:mr-32">
          <h4 class="mb-4 text-2xl font-bold">
            {{ $t('language.message') }}
          </h4>
          <a
            class="p-0 mb-2 cursor-pointer hover:underline underline opacity-70 hover:opacity-100"
            @click="changeLocale('es')"
          >
            {{ $t('language.spanish.message') }} (ES)
          </a>
          <a
            class="p-0 mb-2 cursor-pointer hover:underline underline opacity-70 hover:opacity-100"
            @click="changeLocale('en')"
          >
            {{ $t('language.english.message') }} (EN)
          </a>
        </div>
      </div>
      <div class="flex flex-col items-center flex-1 my-16 lg:my-0 lg:items-end">
        <div class="flex flex-col">
          <div class="flex justify-center mb-8 lg:justify-start">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/platan.us/"
            >
              <inline-svg
                :src="require('assets/images/icons/social/instagram.svg')"
                class="w-8 h-8 mr-2 fill-current"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/platanus/"
            >
              <inline-svg
                :src="require('assets/images/icons/social/github.svg')"
                class="w-8 h-8 mr-2 fill-current"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://facebook.com/platanusocial/"
            >
              <inline-svg
                :src="require('assets/images/icons/social/facebook.svg')"
                class="w-8 h-8 mr-2 fill-current"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/_platanus/"
            >
              <inline-svg
                :src="require('assets/images/icons/social/twitter.svg')"
                class="w-8 h-8 mr-2 fill-current"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/platanus/"
            >
              <inline-svg
                :src="require('assets/images/icons/social/linkedin.svg')"
                class="w-8 h-8 fill-current"
              />
            </a>
          </div>
          <div class="flex flex-col items-center lg:items-start">
            <p class="mr-4 font-bold">
              {{ $t('landing.footer.contact.title') }}
            </p>
            <p class="flex items-center whitespace-nowrap">
              {{ $t('landing.footer.contact.text') }}&nbsp;
              <a
                class="border-b border-white border-dotted cursor-pointer"
                :href="`mailto:${contactEmail}`"
              >
                {{ contactEmail }}
              </a>
              <button
                class="ml-2"
                @click="copyContactEmail"
              >
                <inline-svg
                  :src="require(`assets/images/icons/${copied ? 'check' : 'copy'}-icon.svg`)"
                  class="w-4 h-4 fill-current"
                />
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
const COPY_TIMEOUT_IN_MS = 1500;
const CONTACT_EMAIL = 'mute@platan.us';

export default {
  data() {
    return {
      copied: false,
      currentTimeout: null,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    contactEmail() {
      return CONTACT_EMAIL;
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      window.localStorage.locale = locale;
      window.scrollTo({ top: 0 });
    },
    clearCopyTimeout() {
      if (this.currentTimeout) {
        clearTimeout(this.currentTimeout);
      }
    },
    setCopyTimeout() {
      this.copied = true;
      this.currentTimeout = setTimeout(() => {
        this.copied = false;
      }, COPY_TIMEOUT_IN_MS);
    },
    async copyContactEmail() {
      this.clearCopyTimeout();
      this.setCopyTimeout();
      await navigator.clipboard.writeText(CONTACT_EMAIL);
    },
  },
};
</script>
