<template>
  <div :class="$attrs.class">
    <div
      class="w-full"
      :class="[sizeClasses.mainContainerSizes, {'bg-gray-100': disabled}]"
    >
      <select
        v-bind="filteredAttrs"
        ref="input"
        :value="modelValue"
        :disabled="disabled"
        :placeholder="isSmall && label ? label : null"
        class="w-full bg-transparent bg-right bg-no-repeat border rounded-lg outline-none appearance-none"
        :style="`background-image: url(${require('../../../assets/images/icons/chevron-down.svg')})`"
        :class="[
          sizeClasses.inputSizes, {
            'border-red-500 focus:border-red-600 text-red-400': hasError,
            'border-gray-500 focus:border-purple-500 ': !hasError,
            'text-gray-400': !isSelectedValue && !hasError,
          }]"
        @focus="focus"
        @click="focus"
        @blur="blur"
        @input="$emit('update:model-value', $event.target.value)"
      >
        <option
          v-if="isSmall && label"
          :value="null"
          disabled
        >
          {{ label }}
        </option>
        <option
          v-for="item in options"
          :key="item.id"
          :value="item[keyValue]"
          class="text-black bg-white"
        >
          {{ item.label || item.name }}
        </option>
      </select>
      <mute-input-label
        :label="label"
        :value="isSelectedValue"
        :size="size"
        :focus="focus"
        :focused="focused"
        :has-error="hasError"
      />
    </div>
    <p
      v-if="hasError"
      class="mt-1 text-xs text-red-500 cursor-text"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import MuteInputLabel from './mute-input-label';
import { MuteInputClassesMixin, MuteInputFocusMixin } from './mixins/mute-input';
import useFilteredAttrs from '../../composables/useFilteredAttrs';

export default {
  components: {
    MuteInputLabel,
  },
  mixins: [MuteInputClassesMixin, MuteInputFocusMixin],
  inheritAttrs: false,

  props: {
    modelValue: { type: [String, Number], default: null },
    options: { type: Array, default: () => [] },
    label: { type: String, default: null },
    size: { type: String, default: 'large' },
    disabled: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    errorMessage: { type: String, default: null },
    keyValue: { type: String, default: 'value' },
  },
  emits: ['update:model-value'],
  setup() {
    const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

    return { filteredAttrs };
  },
  computed: {
    isSelectedValue() {
      return this.modelValue !== undefined && this.modelValue !== null;
    },
  },
};
</script>
