<template>
  <mute-multiselect
    v-model="value"
    :has-error="hasError"
    :error-message="errorMessage"
    @blur="handleBlur"
  />
</template>

<script>
import { useField } from 'vee-validate';
import MuteMultiselect from '../mute-multiselect.vue';
import yupI18nErrorMessage from '../../../helpers/yup';

export default {
  components: { MuteMultiselect },
  props: {
    name: { type: String, required: true },
  },

  setup(props) {
    const { value, errorMessage, meta, handleBlur } = useField(props.name);

    return { value, errorKey: errorMessage, meta, handleBlur };
  },
  computed: {
    errorMessage() {
      return yupI18nErrorMessage(this.errorKey);
    },
    hasError() {
      return this.errorKey && this.meta.touched;
    },
  },
};
</script>
