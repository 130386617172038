<template>
  <base-modal :title="$t('meetings.instructions.muteInstructions')">
    <div
      class="p-4 border-t border-purple-400 h-4/5"
    >
      <div
        v-if="instructions"
        class="h-full overflow-y-auto"
      >
        <rich-content :rich-text="instructions" />
      </div>
      <p
        v-else
        class="text-gray-400"
      >
        {{ $t('meetings.instructions.noInstructions') }}
      </p>
    </div>
  </base-modal>
</template>

<script>
import { mapState } from 'vuex';
import RichContent from '../shared/rich-content.vue';

export default {
  components: { RichContent },
  computed: {
    ...mapState(['document']),
    instructions() {
      return this.document.document &&
      this.document.document.instructions &&
      this.document.document.instructions.body;
    },
  },
};
</script>
