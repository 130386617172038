/**
 * Determines if a user is mentioned in a given HTML string, which follows tiptap's
 * schema.
 * @param {string} tiptapHtml - HTML string that follows tiptap's schema
 * @returns {boolean} Whether or not the user is mentioned in the HTML string
 */
export default function isUserMentionedInHtml(tiptapHtml, username) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(tiptapHtml, 'text/html');

  const mention = doc.querySelector(`[data-type=mention][data-id="${username}"]`);

  return Boolean(mention);
}
