import api from './index';

export default {
  destroyTemplate(id) {
    return api({
      method: 'delete',
      url: `/api/internal/document_templates/${id}`,
    });
  },
  createTemplate(body) {
    return api({
      method: 'post',
      url: '/api/internal/document_templates',
      data: body,
    });
  },
  editTemplate(id, body) {
    return api({
      method: 'patch',
      url: `/api/internal/document_templates/${id}`,
      data: body,
    });
  },
};
