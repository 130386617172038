<template>
  <div
    class="relative flex items-center justify-center"
    :class="sizeClasses[0]"
  >
    <input
      :id="id"
      type="checkbox"
      class="hidden"
      :modelValue="modelValue"
      @input="$emit('update:model-value', !modelValue)"
    >
    <label
      :for="id"
      class="inline-block transition-colors duration-300 ease-in-out rounded-full shadow-inner cursor-pointer"
      :class="[sizeClasses[1], modelValue ? 'bg-green-500' : 'bg-gray-400']"
    />
    <label
      :for="id"
      class="absolute left-0 inline-block transition-transform duration-300 ease-in-out bg-white rounded-full shadow cursor-pointer"
      :class="[sizeClasses[2] ,{ 'transform translate-x-full': modelValue }]"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, required: true },
    modelValue: { type: Boolean, required: true },
    size: { type: String, default: 'medium' },
  },
  emits: ['update:model-value'],

  computed: {
    sizeClasses() {
      const mainContainerSizes = {
        small: 'w-9 h-5',
        medium: 'w-11 h-6',
        large: 'w-16 h-8',
      };

      const innerContainerSizes = {
        small: 'w-9 h-5',
        medium: 'w-11 h-6',
        large: 'w-11 h-6',
      };

      const sliderSizes = {
        small: 'w-4 h-4 mx-0.5',
        medium: 'w-5 h-5 mx-0.5',
        large: 'w-8 h-8',
      };

      const sizes = [mainContainerSizes, innerContainerSizes, sliderSizes];

      return sizes.map(size => size[this.size]);
    },
  },
};
</script>
