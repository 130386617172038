<template>
  <div class="z-50">
    <div class="flex items-center justify-between w-screen px-4 bg-purple-500 border-b-2 border-gray-300 lg:z-auto lg:justify-start h-14 lg:h-16">
      <div class="inline-flex items-center h-10 mr-4">
        <a
          class="inline-flex items-center h-10"
          href="/"
        >
          <inline-svg
            class="text-white fill-current h-4/5 lg:h-full"
            :src="require('assets/images/logo.svg')"
          />
        </a>
        <template v-if="pageTitle">
          <div class="hidden h-10 mx-2 border border-white lg:block" />
          <span class="hidden ml-0 text-xl tracking-wide text-white lg:block">
            {{ pageTitle }}
          </span>
        </template>
      </div>
      <div class="flex items-center justify-end space-x-4 lg:flex-1">
        <upvoty-nav-item
          :user-token="currentUser.upvotyToken"
          class="text-xs md:text-base"
        />
        <div class="relative flex items-center my-4">
          <button
            class="grow-0 text-white fill-current h-7 w-7"
            @click="isChangelogModalOpen = true"
          >
            <inline-svg
              :src="require('assets/images/icons/notifications.svg')"
              class="w-6 h-6 md:w-full md:h-full"
            />
          </button>
          <div
            v-if="showChangelogNotification"
            class="hidden lg:block h-4 w-4 absolute right-1 top-1 -m-1.5 bg-red-500 rounded-full"
          />
        </div>
        <dropdown
          v-if="currentTeamId && Object.keys(teams).length > 1"
          class="hidden sm:block"
        >
          <template #button>
            <div class="flex items-center text-base text-white">
              <p>
                {{ teams[currentTeamId].name }}
              </p>
              <inline-svg
                :src="require('../../../assets/images/icons/down-arrow-fill.svg')"
                class="w-6 h-6 fill-current"
              />
            </div>
          </template>
          <template #menu>
            <team-select />
          </template>
        </dropdown>
        <dropdown class="hidden sm:block">
          <template #button>
            <div class="flex items-center text-white">
              <img
                class="h-10 rounded-lg shadow"
                :src="currentUser.avatar"
              >
              <inline-svg
                :src="require('../../../assets/images/icons/down-arrow-fill.svg')"
                class="fill-current"
              />
            </div>
          </template>
          <template #menu>
            <div class="text-xl">
              @{{ currentUser.username }}
            </div>
            <mute-divider
              width="w-full"
              class="my-2"
            />
            <div class="flex flex-col space-y-2">
              <language-select />
              <a
                class="text-purple-500 hover:underline hover:text-purple-600"
                href="/profile"
              >
                {{ $t('profile.myProfile') }}
              </a>
              <a
                class="text-purple-500 hover:underline hover:text-purple-600"
                href="#"
                @click.prevent="signOut"
              >
                {{ $t('profile.logOut') }}
              </a>
            </div>
          </template>
        </dropdown>
        <button
          class="block sm:hidden"
          @click="isMenuOpen = !isMenuOpen"
        >
          <inline-svg
            class="w-6 h-6 text-white fill-current"
            :src="require('assets/images/icons/hamburger-icon.svg')"
          />
        </button>
      </div>
    </div>
    <changelog-modal
      :changelog-items="changelogItems"
      :open="isChangelogModalOpen"
      @update="getChangelogItems"
      @close="isChangelogModalOpen = false"
    />
    <transition
      name="fade"
    >
      <div
        v-if="isMenuOpen"
        class="fixed block w-full h-screen p-4 space-y-4 bg-white md:hidden"
      >
        <div class="flex flex-col space-y-2">
          <div class="flex">
            <img
              class="h-8 mr-2 rounded-lg shadow"
              :src="currentUser.avatar"
            >
            <div class="text-xl">
              @{{ currentUser.username }}
            </div>
          </div>
          <a
            class="text-purple-500 hover:underline hover:text-purple-600"
            href="/profile"
          >
            {{ $t('profile.myProfile') }}
          </a>
          <a
            class="text-purple-500 hover:underline hover:text-purple-600"
            href="#"
            @click.prevent="signOut"
          >
            {{ $t('profile.logOut') }}
          </a>
        </div>
        <mute-divider
          width="w-full"
          color="gray-200"
        />
        <team-select />
        <mute-divider
          width="w-full"
          color="gray-200"
        />
        <language-select />
        <mute-divider
          class="w-full"
          color="gray-200"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { csrfToken } from '@rails/ujs';
import Dropdown from '../shared/dropdown.vue';
import ChangelogModal from '../changelog/changelog-modal.vue';
import changelogItemsApi from '../../api/changelog_items';
import UpvotyNavItem from '../upvoty/upvoty-nav-item.vue';
import TeamSelect from '../nav-bar/team-select.vue';
import LanguageSelect from '../nav-bar/language-select.vue';
import { logInitAmplitudeEvent, setAmplitudeUserId } from '../../helpers/amplitude';

export default {
  components: { Dropdown, ChangelogModal, UpvotyNavItem,
    LanguageSelect, TeamSelect },
  props: {
    pageTitle: {
      type: String,
      default: null,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      changelogItems: null,
      errors: null,
      isMenuOpen: false,
      isChangelogModalOpen: false,
    };
  },
  computed: {
    showChangelogNotification() {
      return this.changelogItems && this.changelogItems.length > 0;
    },
    teams() {
      return this.$store.state.team.teams;
    },
    currentTeamId() {
      return this.$store.state.team.teamId;
    },
    featuresSeenAt() {
      return this.$store.state.user.featuresSeenAt;
    },
  },
  async mounted() {
    this.initializeUser();
    this.initializeTeam();
    await this.getChangelogItems();
  },
  methods: {
    signOut() {
      fetch('/users/sign_out', {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrfToken(),
        },
      }).then(() => window.location.replace('/'));
    },
    async getChangelogItems() {
      this.loading = true;

      changelogItemsApi.getChangelogItems(new Date(this.featuresSeenAt).toISOString())
        .then((response) => {
          this.changelogItems = response.data.changelogItems;
        })
        .catch((error) => {
          if (error.details) {
            this.errors = error.details;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async initializeTeam() {
      this.$store.dispatch('setTeams');
      const teamIdRegexMatch = window.location.pathname.match(/\/teams\/(\d+)\//i);

      const teamId = teamIdRegexMatch ? Number(teamIdRegexMatch[1]) : null;
      logInitAmplitudeEvent(teamId);

      await this.$store.dispatch('getCustomEmojis', teamId);
      if (this.currentTeamId !== teamId) {
        this.$store.dispatch('setTeam', teamId);
      }
    },
    initializeUser() {
      this.$store.dispatch('setUser', this.currentUser);
      setAmplitudeUserId(this.currentUser.id);
    },
  },
};
</script>
