<template>
  <div
    v-click-outside="close"
    class="relative flex items-center h-full px-2 py-1 bg-gray-300 rounded-full"
  >
    <button
      class="flex items-center px-1 text-sm text-black focus:outline-none"
      @click="toggle"
    >
      <inline-svg
        :src="require('assets/images/icons/arrow-down.svg')"
        class="w-2 text-sm"
      />
      <inline-svg
        :src="require('assets/images/icons/sort.svg')"
        class="w-4 mr-2 fill-current"
      />
      <span class="mr-2 text-xs text-gray-600">{{ $t('documents.filter.orderedBy') }}</span>
      <span class="self-start text-sm">{{ $t(`documents.filter.${sortCriteria}`) }}</span>
    </button>
    <div
      class="absolute inset-x-0 top-full"
      :class="{ hidden: !isOpen }"
    >
      <div class="absolute right-0 z-0 w-8 h-8 mr-2 rotate-45 bg-white rounded shadow-md" />
      <div class="absolute flex flex-col w-56 p-4 mt-2 text-black bg-white rounded-md shadow-md">
        <div class="mb-4 text-xl">
          {{ $t('documents.filter.orderBy') }}
        </div>
        <mute-radio
          :model-value="sortCriteria"
          value="date"
          class="mb-4"
          :label="$t('documents.filter.date')"
          @update:model-value="$emit('update:sort-criteria', $event)"
        />
        <mute-radio
          :model-value="sortCriteria"
          value="title"
          class="mb-4"
          :label="$t('documents.filter.title')"
          @update:model-value="$emit('update:sort-criteria', $event)"
        />
        <hr class="mb-4">
        <mute-radio
          :model-value="order"
          value="ascending"
          class="mb-4"
          :label="$t('documents.filter.ascending')"
          @update:model-value="$emit('update:order', $event)"
        />
        <mute-radio
          :model-value="order"
          value="descending"
          class="mb-4"
          :label="$t('documents.filter.descending')"
          @update:model-value="$emit('update:order', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sortCriteria: { type: String, required: true },
    order: { type: String, required: true },
  },
  emits: ['update:sort-criteria', 'update:order', 'dropdown-open'],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit('dropdown-open', this.isOpen);
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
