import { isArray } from 'lodash';
import { notify } from '@kyvg/vue3-notification';

import mentionNotifications from './mentionNotifications';
import documentConfigurationNotifications from './documentConfigNotifications';

const notificationDataGetters = {
  ...mentionNotifications,
  ...documentConfigurationNotifications,
};

function showNotification(notificationData) {
  if (!notificationData) return;
  if (isArray(notificationData)) {
    notificationData.forEach((data) => showNotification(data));

    return;
  }
  notify(notificationData);
}

function handleNotification(action, object, store) {
  if (notificationDataGetters[action]) {
    const notificationData = notificationDataGetters[action](object, store);
    showNotification(notificationData);
  }
}

const notificationPlugin = {
  install(app, { store }) {
    app.config.globalProperties.$showNotification = (action, object) => {
      handleNotification(action, object, store);
    };
  },
};

export default notificationPlugin;
