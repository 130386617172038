<template>
  <component
    :is="tag"
    class="flex items-center transition-colors duration-150 ease-in-out focus:outline-none"
    :class="[colorClasses, sizeClasses]"
    :disabled="disabled"
  >
    <inline-svg
      v-if="iconFileName"
      :src="require(`../../../assets/images/icons/${iconFileName}`)"
      class="fill-current grow-0"
      :class="[svgClasses, !!$slots.default && 'mr-2']"
    />
    <div class="flex-1 text-center">
      <slot />
    </div>
    <inline-svg
      v-if="rightIconFileName"
      :src="require(`../../../assets/images/icons/${rightIconFileName}`)"
      class="fill-current grow-0"
      :class="[svgClasses, !!$slots.default && 'ml-2']"
    />
  </component>
</template>

<script>
export default {
  props: {
    tag: { type: String, default: 'button' },
    variant: { type: String, default: 'purple' },
    iconFileName: { type: String, default: null },
    size: { type: String, default: 'md' },
    disabled: { type: Boolean, default: false },
    rightIconFileName: { type: String, default: null },
    outlined: { type: Boolean, default: false },
    borderless: { type: Boolean, default: false },
    toolbar: { type: Boolean, default: false },
  },

  computed: {

    colorClasses() {
      const classes = {
        purple: 'text-white bg-purple-500 hover:bg-purple-600',
        green: 'text-white bg-green-500 hover:bg-green-600',
        white: 'text-black bg-white hover:bg-gray-100',
        red: 'text-white bg-red-500 hover:bg-red-600',
        amber: 'text-amber-600 bg-amber-100 hover:bg-amber-200',
        disabled: 'text-gray-300 bg-white-300 cursor-default',
        gray: 'text-white bg-gray-500 hover:bg-gray-600',
      };

      const outlinedClasses = {
        purple: 'text-purple-500 bg-white border border-purple-500 hover:bg-gray-100',
        green: 'text-green-500 bg-white border border-green-500 hover:bg-gray-100',
        white: 'text-black bg-white hover:bg-gray-100 border border-black',
        red: 'text-red-500 bg-white border border-red-500 hover:bg-gray-100',
        amber: 'text-amber-600 bg-white border border-amber-500 hover:bg-amber-100',
        disabled: 'text-gray-300 bg-white-300 border border-gray-300 cursor-default',
        gray: 'text-gray-500 bg-white border border-gray-500 hover:bg-gray-100',
      };

      const borderlessClasses = {
        purple: 'text-purple-500 hover:text-purple-600',
        green: 'text-green-500 hover:text-green-600',
        white: 'text-black hover:text-gray-100',
        red: 'text-red-500 hover:text-red-600',
        amber: 'text-amber-600 hover:text-amber-700',
        disabled: 'text-gray-300 cursor-default',
        gray: 'text-gray-500 hover:text-gray-600',
      };

      const variant = this.disabled ? 'disabled' : this.variant;
      const roundedClass = this.size === 'sm' ? 'rounded' : 'rounded-lg';

      if (this.outlined) {
        return `${outlinedClasses[variant]} ${roundedClass} shadow`;
      }

      return this.borderless ? borderlessClasses[variant] : `${classes[variant]} ${roundedClass} shadow`;
    },
    sizeClasses() {
      const paddingSmall = this.toolbar ? 'p-1' : 'p-1.5';
      const classesWithText = this.size === 'sm' ? 'text-sm py-1 px-2' : 'py-2 px-3 text-sm md:text-base';

      const classesWithoutText = this.size === 'sm' ? `${paddingSmall} text-sm` : 'p-3 text-base';

      return this.$slots.default ? classesWithText : classesWithoutText;
    },
    svgClasses() {
      switch (this.size) {
      case 'sm':
        return 'w-3 h-3';
      case 'md':
        return 'w-4 h-4';
      case 'lg':
        return 'w-6 h-6';
      default:
        return '';
      }
    },

  },
};
</script>
