<template>
  <session-wrapper
    :show-back-button="true"
    :back-button-href="backHref"
    :header="$t('sessions.passwordRecovery.message')"
  >
    <template #content>
      <slot name="errors" />
      <p class="mb-6">
        {{ $t('sessions.passwordRecovery.newPassword.enterPassword') }}
      </p>
      <vv-form
        v-slot="{ meta }"
        :action="action"
        method="post"
        class="flex flex-col"
        :initial-values="{
          'user[password]': '',
          'user[password_confirmation]': '',
        }"
        :validation-schema="validationSchema"
      >
        <csrf-input />
        <input
          type="hidden"
          name="_method"
          :value="method"
        >
        <input
          type="hidden"
          :value="token"
          name="user[reset_password_token]"
        >
        <mute-vv-input
          class="mb-6"
          :label="$t('sessions.passwordRecovery.newPassword.new')"
          type="password"
          name="user[password]"
        />
        <mute-vv-input
          class="mb-6"
          :label="$t('sessions.passwordRecovery.newPassword.confirmation')"
          type="password"
          name="user[password_confirmation]"
        />
        <mute-cta-button
          type="submit"
          :disabled="!meta.valid || !meta.dirty"
        >
          {{ $t('sessions.passwordRecovery.newPassword.recover') }}
        </mute-cta-button>
      </vv-form>
    </template>
    <template #footer>
      <session-footer-link href="/users/sign_in">
        {{ $t('sessions.login') }}
      </session-footer-link>
    </template>
  </session-wrapper>
</template>

<script>
import { Form as VvForm } from 'vee-validate';
import * as yup from 'yup';
import SessionFooterLink from '../components/session/session-footer-link.vue';
import sessionWrapper from '../components/session/session-wrapper.vue';
import CsrfInput from '../components/shared/csrf-input.vue';
import { MINIMUM_PASSWORD_CHAR_LENGTH } from '../utils/constants';

export default {
  components: { sessionWrapper, CsrfInput, SessionFooterLink, VvForm },
  props: {
    method: {
      type: String,
      default: 'put',
    },
    action: {
      type: String,
      default: '/users/password',
    },
    backHref: {
      type: String,
      default: '/',
    },
  },
  setup() {
    return {
      validationSchema: yup.object({
        'user[password]': yup.string().min(MINIMUM_PASSWORD_CHAR_LENGTH).required(),
        'user[password_confirmation]': yup.string().equalToSibling('user[password]'),
      }),
    };
  },
  computed: {
    token() {
      const urlParams = new URLSearchParams(window.location.search);

      return urlParams.get('reset_password_token');
    },
  },
};
</script>
