<template>
  <mute-checkbox
    v-bind="$attrs"
    v-model="value"
    :name="name"
  />
</template>

<script>
import { useField } from 'vee-validate';
import MuteCheckbox from '../mute-checkbox.vue';

export default {
  components: { MuteCheckbox },
  props: {
    name: { type: String, required: true },
  },

  setup(props) {
    const { value } = useField(props.name);

    return { value };
  },
};
</script>
