<template>
  <validation-provider
    v-click-outside="hideDatePicker"
    class="relative"
  >
    <div class="relative w-full h-16">
      <input
        v-model="dateValue"
        type="text"
        class="absolute inset-0 w-full px-3 pt-8 pb-3 bg-no-repeat border border-gray-500 rounded-lg outline-none focus:border-2 focus:border-purple-500"
        @click="showDatePicker"
      >
      <button
        class="absolute right-0 w-6 h-6 mt-6 mr-4"
        @click="showDatePicker"
      >
        <inline-svg
          :src="require('assets/images/icons/calendar.svg')"
          class="w-full h-full text-gray-500 fill-current"
        />
      </button>
      <div class="pl-3 select-none">
        <label
          class="absolute mt-6 text-gray-500 transition-all duration-100 ease-in-out origin-top-left scale-75 -translate-y-1/2 cursor-text"
        >
          <span>{{ label }}</span>
          <span
            v-if="rules.includes('required')"
            class="text-sm text-purple-500"
          >
            *</span>
        </label>
      </div>
    </div>
    <vc-date-picker
      v-if="datePicker"
      v-model="dateModel"
      mode="dateTime"
      class="absolute z-10"
    />
  </validation-provider>
</template>
<script>
export default {
  props: {
    date: { type: String, required: true },
    label: { type: String, default: 'Fecha y hora' },
    rules: { type: String, default: '' },
  },
  emits: ['change-date'],
  data() {
    return {
      dateModel: new Date(this.date),
      datePicker: false,
    };
  },
  computed: {
    patternSrc() {
      return require('assets/images/icons/calendar.svg');
    },
    dateValue() {
      return this.dateModel.toLocaleString();
    },
  },
  watch: {
    dateModel() {
      this.$emit('change-date', this.dateModel.toISOString());
    },
  },
  methods: {
    showDatePicker() {
      this.datePicker = true;
    },
    hideDatePicker() {
      this.datePicker = false;
    },
  },
};
</script>
