<template>
  <tip-tap-editor
    v-bind="$attrs"
    v-model="value"
    @blur="handleBlur"
  />
</template>

<script>
import { useField } from 'vee-validate';
import TipTapEditor from '../tiptap/tiptap.vue';

export default {
  components: { TipTapEditor },
  props: {
    name: { type: String, required: true },
  },

  setup(props) {
    const { value, handleBlur } = useField(props.name);

    return { value, handleBlur };
  },
};
</script>
