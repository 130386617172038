<template>
  <mute-select
    v-bind="$attrs"
    v-model="value"
    :name="name"
    :has-error="hasError"
    :error-message="errorMessage"
    @blur="handleBlur"
  />
</template>

<script>
import { useField } from 'vee-validate';
import MuteSelect from '../mute-select.vue';
import yupI18nErrorMessage from '../../../helpers/yup';

export default {
  components: { MuteSelect },
  props: {
    name: { type: String, required: true },
  },

  setup(props) {
    const { value, meta, errorMessage, handleBlur } = useField(props.name, undefined, {
      validateOnMount: true,
    });

    return { value, meta, errorKey: errorMessage, handleBlur };
  },
  computed: {
    errorMessage() {
      return yupI18nErrorMessage(this.errorKey);
    },
    hasError() {
      return this.errorKey && this.meta.touched;
    },
  },
};
</script>
