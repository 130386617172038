import { createStore } from 'vuex';
import VuexPersist from 'vuex-persist';
import document from './document';
import emoji from './emoji';
import user from './user';
import team from './team';

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: (state) => ({
    document: {
      savedAnnouncementsByDocument: state.document.savedAnnouncementsByDocument,
      allowUnreadMessages: state.document.allowUnreadMessages,
    },
  }),
});

const vuexSessionStorage = new VuexPersist({
  key: 'vuex',
  storage: window.sessionStorage,
  reducer: (state) => ({
    document: {
      mutedSections: state.document.mutedSections,
      mutedAnnouncements: state.document.mutedAnnouncements,
      usersReady: state.document.usersReady,
    },
    team: {
      teamId: state.team.teamId,
      teams: state.team.teams,
    },
  }),
});

const store = createStore({
  modules: {
    document,
    emoji,
    user,
    team,
  },
  plugins: [vuexLocalStorage.plugin, vuexSessionStorage.plugin],
});

export default store;
