<template>
  <div
    v-click-outside="() => isOpen = false"
    class="relative flex items-center h-full lg:w-auto"
  >
    <div
      class="w-full cursor-pointer lg:w-auto"
      @click="toggle"
    >
      <slot name="button" />
    </div>
    <div
      v-if="hasLargeScreen"
      class="absolute right-0 min-w-full top-full"
      :class="{ hidden: !isOpen }"
    >
      <div
        class="absolute top-0 right-0 z-0 w-8 h-8 mr-2 rotate-45 rounded shadow-md"
        :class="tipClasses"
      />
      <div
        class="absolute top-0 right-0 flex flex-col p-4 rounded-md shadow-md"
        :class="[boxClass, boxClasses]"
      >
        <slot name="menu" />
      </div>
    </div>
    <base-modal
      v-else
      :class="{ hidden: !isOpen }"
      :open="isOpen"
      :with-close-option="false"
      @close="isOpen = false"
    >
      <slot name="menu" />
    </base-modal>
  </div>
</template>

<script>
export default {
  props: {
    boxClass: { type: String, default: 'w-64' },
    color: { type: String, default: 'white' },
    variant: { type: String, default: 'normal' },
  },
  emits: ['dropdown-open'],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    boxClasses() {
      const variantOptions = {
        normal: 'mt-2',
        noTip: 'w-full',
      };
      const colorOptions = {
        white: 'text-black bg-white',
        purple: 'bg-purple-500 text-white',
      };

      return [variantOptions[this.variant], colorOptions[this.color]];
    },
    tipClasses() {
      const variantOptions = {
        normal: '',
        noTip: 'hidden',
      };
      const colorOptions = {
        white: 'bg-white',
        purple: 'bg-purple-500',
      };

      return [variantOptions[this.variant], colorOptions[this.color]];
    },
    hasLargeScreen() {
      return this.$store.getters.hasLargeScreen;
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit('dropdown-open', this.isOpen);
    },
  },
};
</script>

<style lang="scss">
  .v--modal-overlay .v--modal-box {
    @apply overflow-visible;
  }
</style>
