const initialState = {
  users: null,
  usersReady: {},
};

const actions = {
  updateUserPosition({ commit }, userPosition) {
    commit('updateUserPosition', userPosition);
  },
  updateUserReady({ commit }, userReady) {
    commit('updateUserReady', userReady);
  },
};

const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  },
  updateUserPosition(state, payload) {
    state.users[payload.id].position = payload;
  },
  updateUserReady(state, payload) {
    const { documentId } = payload;
    if (!state.usersReady[documentId]) {
      state.usersReady[documentId] = {};
    }
    state.usersReady[documentId][payload.id] = payload.ready;
  },
};

export default {
  state: initialState,
  actions,
  mutations,
};
