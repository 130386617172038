function createAnnouncementNotification(announcement, { getters }) {
  const { body, author, id, sectionId } = announcement;
  if (!getters.isUsernameInMessage(body)) return null;

  return {
    data: {
      author: author.username,
      authorImg: author.avatar,
      sectionId,
      announcementId: id,
      type: 'mention',
      text: body,
    },
  };
}

function createCommentNotification(comment, { getters }) {
  const { body, announcementId, author } = comment;
  if (!getters.isUsernameInMessage(body)) return null;
  const sectionId = getters.getSectionIdByAnnouncementId(announcementId);

  return {
    data: {
      author: author.username,
      authorImg: author.avatar,
      sectionId,
      announcementId,
      type: 'mention',
      text: body,
    },
  };
}

const mentionNotifications = {
  createAnnouncement: createAnnouncementNotification,
  createComment: createCommentNotification,
};

export default mentionNotifications;
