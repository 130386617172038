<template>
  <div
    v-if="errors.length > 0"
    class="p-4 mb-4 text-red-500 bg-red-100 border-2 border-red-500"
  >
    <p><slot /></p>
    <ul class="ml-4 list-disc list-inside">
      <li
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: { errors: { type: Array, required: true } },
};
</script>
