<template>
  <div
    class="flex items-center w-full mb-1"
  >
    <label
      :for="id"
      class="flex items-center w-full justify-between cursor-pointer"
    >
      <div
        v-if="!!text"
        class="w-fit"
      >
        {{ text }}
      </div>
      <mute-switch
        :id="id"
        :model-value="modelValue"
        @update:model-value="$emit('update:model-value', $event)"
      />
    </label>
  </div>
</template>

<script>
import MuteSwitch from './mute-switch.vue';

export default {
  components: { MuteSwitch },
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:model-value'],
};
</script>
