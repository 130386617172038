<template>
  <div
    v-if="count > 0"
    class="w-6 h-6 text-xs leading-6 text-center rounded-full"
    :class="[textColor, backgroundColor]"
  >
    {{ count }}
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    textColor: {
      type: String,
      default: 'text-white',
    },
    backgroundColor: {
      type: String,
      default: 'bg-teal-600',
    },
  },
};
</script>
