<template>
  <div
    class="border-b"
    :class="[`border-${color}`, width]"
  />
</template>

<script>
export default {
  props: {
    color: { type: String, default: 'gray-400' },
    width: { type: String, default: 'w-64' },
  },
};
</script>
