<template>
  <div class="flex">
    <p>
      {{ $t('language.message') }}
    </p>
    <div class="flex ml-auto text-xs space-x-1">
      <button
        class="w-5 h-5 flex items-center justify-center rounded"
        :class="selectedLanguage === $t('language.spanish.abbreviation') ? 'bg-slate-200': 'bg-slate-100'"
        @click="changeLocale($t('language.spanish.abbreviation'))"
      >
        {{ $t('language.spanish.abbreviation') }}
      </button>
      <button
        class="w-5 h-5 flex items-center justify-center rounded"
        :class="selectedLanguage === $t('language.english.abbreviation') ? 'bg-slate-200': 'bg-slate-100'"
        @click="changeLocale($t('language.english.abbreviation'))"
      >
        {{ $t('language.english.abbreviation') }}
      </button>
    </div>
  </div>
</template>
<script>
import UserApi from '../../api/users';

export default {
  computed: {
    selectedLanguage() {
      const { user } = this.$store.state;
      if (user && user.locale) return user.locale;

      return this.$i18n.locale;
    },
  },
  methods: {
    changeLocale(language) {
      this.$i18n.locale = language;
      this.$store.dispatch('setLocale', language);
      window.localStorage.locale = language;
      UserApi.update({ user: { locale: language } });
    },
  },
};
</script>
