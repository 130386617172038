<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="prose"
    v-html="code"
  />
</template>

<script>

export default {
  props: {
    code: { type: String, required: true },
  },
};
</script>
