<template>
  <div
    class="flex flex-row items-center justify-center h-5 px-1 py-0.5 rounded-md"
    :class="[colorClasses, { 'flex-row-reverse': iconRight }]"
  >
    <inline-svg
      v-if="iconFileName"
      class="w-2 h-2 fill-current"
      :class="iconHorizontalMargin"
      :src="require(`assets/images/icons/${iconFileName}`)"
    />
    <div class="text-xs text-center">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconFileName: { type: String, default: null },
    variant: { type: String, default: 'primary' },
    iconRight: { type: Boolean, default: false },
  },

  computed: {
    colorClasses() {
      const classes = {
        primary: 'text-purple-500 bg-purple-100 hover:bg-purple-200',
        success: 'text-green-500 bg-green-100 hover:bg-green-200',
        error: 'text-red-500 bg-red-100 hover:bg-red-200',
        warning: 'text-amber-600 bg-amber-100 hover:bg-amber-200',
        disabled: 'text-gray-500 bg-gray-100 hover:bg-gray-200',
      };

      return classes[this.variant];
    },

    iconHorizontalMargin() {
      return this.iconRight ? 'ml-1.5' : 'mr-1.5';
    },
  },

};
</script>
