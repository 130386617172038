<template>
  <div class="flex p-8 sm:px-24 sm:py-8 overflow-hidden">
    <div class="overflow-hidden flex-1">
      <div class="flex justify-around my-8 lg:justify-between">
        <documents-filter
          v-model:sort-criteria="sortCriteria"
          v-model:order="order"
          class="lg:ml-8"
        />
        <div class="flex lg:mr-8">
          <dropdown
            color="purple"
            variant="noTip"
          >
            <template #button>
              <div class="flex items-center text-white">
                <mute-button icon-file-name="down-arrow.svg">
                  {{ $t('documents.create.message') }}
                </mute-button>
              </div>
            </template>
            <template #menu>
              <a
                class="flex items-center gap-4 mb-4 text-sm text-black lg:text-white hover:underline hover:text-gray-200"
                :href="`/teams/${teamId}/documents/new`"
              >
                <inline-svg
                  :src="require('assets/images/icons/heavy-plus.svg')"
                  class="w-4 h-4 fill-current"
                />
                {{ $t('documents.create.newMessage') }}
              </a>
              <a
                class="flex items-center gap-4 text-sm text-black lg:text-white hover:underline hover:text-gray-200"
                :href="`/teams/${teamId}/templates/new_document`"
              >
                <inline-svg
                  :src="require('assets/images/icons/copy-icon.svg')"
                  class="w-4 h-4 fill-current"
                />
                {{ $t('documents.create.templateMessage') }}
              </a>
            </template>
          </dropdown>
        </div>
      </div>
      <documents-index-table
        v-if="documents.length > 0"
        class="h-full overflow-y-auto"
        :documents="documents"
        :hosted-document-ids="hostedDocumentIds"
        :criteria="sortCriteria"
        :order="order"
        :team-id="teamId"
      />
      <p
        v-else
        class="p-4 text-sm text-gray-500"
      >
        {{ $t('documents.dontExist') }}
      </p>
    </div>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import DocumentsFilter from '../components/documents/documents-filter.vue';
import DocumentsIndexTable from '../components/documents/documents-table-deprecated.vue';
import Dropdown from '../components/shared/dropdown.vue';

export default {
  components: { DocumentsFilter, DocumentsIndexTable, Dropdown },
  props: {
    documents: { type: Array, required: true },
    hostedDocumentIds: { type: Array, required: true },
    team: { type: Object, required: true },
    teamMembers: { type: Array, required: true },
    userTeamAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      sortCriteria: 'date',
      order: 'descending',
    };
  },
  computed: {
    teamId() {
      return this.team.id;
    },
  },
  methods: {
    capitalize,
  },
};
</script>
