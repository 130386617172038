<template>
  <div data-upvoty />
</template>

<script>
export default {
  props: {
    userToken: { type: String, required: true },
  },
  emits: ['initialized'],
  computed: {
    upvotyScriptUrl() {
      return `https://${process.env.UPVOTY_BASE_URL}/javascript/upvoty.embed.js`;
    },
  },
  async mounted() {
    try {
      await this.loadUpvoty();
    } finally {
      this.$emit('initialized');
    }
  },
  methods: {
    initializeUpvoty() {
      window.upvoty.init('render', {
        ssoToken: this.userToken,
        baseUrl: process.env.UPVOTY_BASE_URL,
        boardHash: process.env.UPVOTY_BOARD_HASH,
      });
    },
    loadUpvoty() {
      return new Promise((resolve, reject) => {
        const upvotyScript = document.createElement('script');
        upvotyScript.setAttribute('src', this.upvotyScriptUrl);
        document.head.appendChild(upvotyScript);

        upvotyScript.addEventListener('load', () => {
          this.initializeUpvoty();
          resolve();
        });

        upvotyScript.addEventListener('error', (err) => reject(err));
      });
    },
  },
};
</script>
