import api from './index';

export default {
  index(teamId) {
    return api({
      method: 'get',
      url: `/api/internal/teams/${teamId}/tags`,
    });
  },
};
