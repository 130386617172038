<template>
  <session-wrapper
    :show-back-button="true"
    back-button-href="/sessions"
    :header="$t('sessions.login')"
  >
    <template #content>
      <vv-form
        v-slot="{ meta }"
        action="/users/sign_in"
        method="post"
        class="flex flex-col"
        :initial-values="{ 'user[email]': '', 'user[password]': '' }"
        :validation-schema="validationSchema"
      >
        <csrf-input />
        <mute-vv-input
          class="mb-6"
          :label="$t('sessions.signUp.fields.email')"
          type="text"
          name="user[email]"
        />
        <mute-vv-input
          class="mb-6"
          :label="$t('sessions.signUp.fields.password')"
          type="password"
          name="user[password]"
        />
        <mute-checkbox
          id="remember_me"
          v-model="rememberMe"
          class="mb-6"
          :label="$t('sessions.remember')"
        />
        <slot name="errors" />
        <input
          name="user[remember_me]"
          type="hidden"
          :value="rememberMe ? '1' : '0'"
        >
        <mute-cta-button
          type="submit"
          class="mb-6"
          :disabled="!meta.valid || !meta.dirty"
        >
          {{ $t('sessions.login') }}
        </mute-cta-button>
        <a
          href="/users/password/new"
          class="flex justify-center text-sm text-green-500 hover:underline hover:text-green-600"
        >
          {{ $t('sessions.forgotPassword') }}
        </a>
      </vv-form>
    </template>
    <template #footer>
      <session-footer-link href="/users/sign_up">
        {{ $t('sessions.signUp.signUp') }}
      </session-footer-link>
    </template>
  </session-wrapper>
</template>

<script>
import { Form as VvForm } from 'vee-validate';
import * as yup from 'yup';
import SessionFooterLink from '../components/session/session-footer-link.vue';
import SessionWrapper from '../components/session/session-wrapper.vue';
import CsrfInput from '../components/shared/csrf-input.vue';
import MuteVvInput from '../components/shared/vee-validate/mute-vv-input.vue';

export default {
  components: { SessionWrapper, CsrfInput, SessionFooterLink, MuteVvInput, VvForm },
  setup() {
    return {
      validationSchema: yup.object({
        'user[email]': yup.string().email().required(),
        'user[password]': yup.string().required(),
      }),
    };
  },
  data() {
    return {
      rememberMe: false,
    };
  },
};
</script>
