<template>
  <div :class="$attrs.class">
    <div
      class="relative border border-gray-500 rounded-lg outline-none"
      :class="[
        disabled && 'bg-gray-100',
        hasError && 'border-red-500',
        focused && !hasError && 'border-purple-500',
        sizeClasses.mainContainerSizes,
      ]"
    >
      <vue-multiselect
        v-bind="filteredAttrs"
        :class="[
          'multiselect--with-error' && hasError,
          'multiselect--large' && size == 'large',
          sizeClasses.inputSizes
        ]"
        :model-value="modelValue"
        :disabled="disabled"
        multiple
        :placeholder="valuePresent ? placeholder : label"
        @open="focused = true"
        @close="handleBlur"
        @update:model-value="$emit('update:modelValue', $event)"
      />
      <label
        v-if="size !== 'small'"
        class="absolute top-0 text-gray-400 transition-all duration-100 ease-in-out origin-top-left -translate-y-1/2 select-none cursor-text"
        :class="[
          sizeClasses.labelSizes,
          focusedInputClasses,
          { 'text-red-300': hasError }
        ]"
        @click="focused = true"
      >
        {{ label }}
      </label>
    </div>
    <p
      v-if="hasError"
      class="mt-1 text-xs text-red-500 cursor-text"
      :class="errorMessageClasses"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import useFilteredAttrs from '../../composables/useFilteredAttrs';

export default {
  components: { VueMultiselect },
  inheritAttrs: false,
  props: {
    modelValue: { type: Array, required: true },
    label: { type: String, required: true },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: 'large' },
    hasError: { type: Boolean, default: false },
    errorMessage: { type: String, default: null },
  },
  emits: ['update:modelValue', 'blur'],
  setup() {
    const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

    return { filteredAttrs };
  },

  data() {
    return {
      focused: false,
    };
  },
  computed: {
    valuePresent() {
      return this.modelValue.length > 0;
    },
    sizeClasses() {
      const mainContainerSizes = {
        small: 'min-h-full',
        large: 'relative',
      };

      const inputSizes = {
        small: 'py-1',
        large: 'mt-4 md:mt-6',
      };

      const labelSizes = {
        small: 'pl-1.5',
        large: 'pl-2 md:pl-3',
      };

      return {
        mainContainerSizes: mainContainerSizes[this.size],
        inputSizes: inputSizes[this.size],
        labelSizes: labelSizes[this.size],
      };
    },

    errorMessageClasses() {
      const errorMessageSizes = {
        small: 'ml-1.5',
        large: 'ml-2 md:ml-3',
      };

      return errorMessageSizes[this.size];
    },
    focusedInputClasses() {
      const notFocusedSizes = {
        large: 'mt-6 md:mt-8',
      };

      const focusedSizes = {
        large: 'mt-4 scale-75 md:mt-6',
      };

      return (this.focused || this.valuePresent ? focusedSizes : notFocusedSizes).large;
    },
  },
  methods: {
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
  },
};
</script>

<style lang="scss">
.multiselect {
  @apply rounded-lg;

  &__tags {
    @apply border-0 pt-2 pl-2 max-h-40 overflow-y-auto overflow-x-hidden rounded-lg bg-transparent;
  }

  &--large &__tags {
    @apply pl-3 pb-2;
  }

  &__placeholder {
    @apply text-base mb-0 invisible;
  }

  &__option--highlight,
  &__option--highlight:after {
    @apply text-purple-500 bg-purple-200;
  }

  &__option--selected {
    @apply font-normal text-purple-500 bg-purple-100;
  }

  &__option--selected.multiselect__option--highlight,
  &__option--selected.multiselect__option--highlight:after {
    @apply text-red-500 bg-red-100;
  }

  &__tag {
    @apply mb-0 text-xs md:text-sm;
  }

  &__tag,
  &__tag-icon::after {
    @apply text-purple-500 bg-purple-100;
  }

  &__tag-icon {
    @apply flex items-center w-2 mx-2;
  }

  &__input {
    @apply pl-0;
  }

  &__tag-icon:hover  {
    @apply text-purple-700 bg-purple-100;
  }

  &--with-error &__placeholder,
  &--with-error &__input::placeholder {
    @apply text-red-500;
  }

  &--with-error &__select:before {
    @apply border-t-red-500;
  }

  &--with-error &__tag-icon::after,
  &--with-error &__tag-icon:hover,
  &--with-error &__tag {
    @apply text-red-500 bg-red-100;
  }

  &--disabled &__tag,
  &--disabled &__tag-icon::after {
    @apply bg-gray-200 text-gray-500;
  }

  &--disabled &__tags,
  &--disabled &__input,
  &--disabled &__select {
    @apply bg-gray-100;
  }
}
</style>
