<template>
  <div
    v-if="!templateInfo"
    class="flex flex-col items-center justify-center w-full h-full"
  >
    <p>
      {{ $t('templates.noSelected') }}
    </p>
  </div>
  <div
    v-else
    class="flex flex-col w-full h-full p-3 pb-8"
  >
    <div
      class="flex justify-between w-full"
    >
      <p class="text-xl">
        {{ templateName }}
      </p>
      <div
        v-if="!readOnly"
        class="flex"
      >
        <mute-button
          :variant="'green'"
          :outlined="true"
          class="w-auto mr-1"
          tag="a"
          :href="`${templateInfo.id}/edit`"
        >
          {{ $t('templates.preview.edit') }}
        </mute-button>
        <mute-button
          :variant="'red'"
          :outlined="true"
          class="w-auto"
          @click="openTemplateModal"
        >
          {{ $t('templates.preview.delete.cta') }}
        </mute-button>
      </div>
    </div>
    <div class="mb-4 text-sm text-gray-500">
      <p
        v-if="templateRecurrent"
      >
        {{ $t('templates.preview.recurrence', {
          weeks: templateWeeks, plural: templateWeeks > 1 ? 's' : '',
          day: templateDay, hour: templateHour
        }) }}
      </p>
      <p v-else>
        Sin recurrencia
      </p>
    </div>
    <div class="flex flex-col">
      <div class="flex items-center mb-1">
        <p class="mr-1 font-bold">
          {{ $t('templates.preview.participants') }}
        </p>
        <p class="mt-1 text-xs text-gray-400">
          ({{ participants.length }})
        </p>
      </div>
      <div
        class="flex flex-wrap"
      >
        <mute-pill
          v-for="(user, index) in participants"
          v-show="showParticipants || index < 15"
          :key="`u-${index}`"
          class="mb-1 mr-1 text-xs bg-gray-100 shadow"
          variant="inactive"
          disabled
        >
          {{ user.username }}
        </mute-pill>
        <button
          v-show="participants.length > 15"
          class="flex items-center mb-1 mr-1 text-green-500 hover:underline"
          @click="() => showParticipants = !showParticipants"
        >
          <inline-svg
            class="text-green-500 fill-current"
            :src="require(`assets/images/icons/${showParticipants ? 'up' : 'down'}-arrow-fill.svg`)"
          />
          {{ $t(`templates.preview.${showParticipants ? 'showLess' : 'showMore'}`) }}
        </button>
      </div>
    </div>
    <div class="flex flex-col mt-4">
      <div class="flex items-center mb-1">
        <p class="mr-1 font-bold">
          {{ $t('templates.preview.moderators') }}
        </p>
        <p class="mt-1 text-xs text-gray-400">
          ({{ moderators.length }})
        </p>
      </div>
      <div
        class="flex flex-wrap"
      >
        <mute-pill
          v-for="(user, index) in moderators"
          v-show="showModerators || index < 15"
          :key="`u-${index}`"
          class="mb-1 mr-1 text-xs bg-gray-100 shadow"
          variant="inactive"
          disabled
        >
          {{ user.username }}
        </mute-pill>
        <button
          v-show="moderators.length > 15"
          class="flex items-center mb-1 mr-1 text-green-500 hover:underline"
          @click="() => showModerators = !showModerators"
        >
          <inline-svg
            class="text-green-500 fill-current"
            :src="require(`assets/images/icons/${showModerators ? 'up' : 'down'}-arrow-fill.svg`)"
          />
          {{ $t(`templates.preview.${showModerators ? 'showLess' : 'showMore'}`) }}
        </button>
      </div>
    </div>
    <div class="flex flex-col mt-4">
      <p class="mb-1 font-bold">
        {{ $t('templates.preview.sections') }}
      </p>
      <div
        class="flex flex-wrap"
      >
        <div
          v-for="(section, index) in sections"
          :key="`u-${index}`"
          class="w-48 p-2 mb-2 mr-2 text-sm rounded shadow bg-gray-50"
        >
          {{ section.title }}
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-4">
      <p class="mb-1 font-bold">
        {{ $t('templates.preview.instructions') }}
      </p>
      <tip-tap-editor
        disabled
        hide-toolbar
        :disabled-extensions="['Image', 'Tag', 'Mention']"
        :model-value="instructions.body"
        style="w-full text-gray-500"
      />
    </div>
    <div class="flex flex-col mt-4">
      <p class="mb-1 font-bold">
        {{ $t('templates.preview.configuration.title') }}
      </p>
      <div
        class="flex"
      >
        <div
          class="flex mr-2"
          :class="{ 'opacity-25': !configurations.announcementsAllowed }"
        >
          <inline-svg
            class="w-4 h-4 text-xs text-center rounded-full"
            :class="[configurations.announcementsAllowed ? 'bg-green-500' : 'bg-gray-500']"
            :src="require(`assets/images/icons/check-icon-white.svg`)"
          />
          <p class="ml-1 text-xs text-gray-500 border-b border-gray-500 border-dotted">
            {{ $t('templates.preview.configuration.announcements') }}
          </p>
        </div>
        <div
          class="flex mr-2"
          :class="{ 'opacity-25': !configurations.commentsAllowed }"
        >
          <inline-svg
            class="w-4 h-4 text-xs text-center rounded-full"
            :class="[configurations.commentsAllowed ? 'bg-green-500' : 'bg-gray-500']"
            :src="require(`assets/images/icons/check-icon-white.svg`)"
          />
          <p class="ml-1 text-xs text-gray-500 border-b border-gray-500 border-dotted">
            {{ $t('templates.preview.configuration.comments') }}
          </p>
        </div>
        <div
          class="flex"
          :class="{ 'opacity-25': !configurations.readyStatusAllowed }"
        >
          <inline-svg
            class="w-4 h-4 text-xs text-center rounded-full"
            :class="[configurations.readyStatusAllowed ? 'bg-blue-500' : 'bg-gray-500']"
            :src="require(`assets/images/icons/check-icon-white.svg`)"
          />
          <p class="ml-1 text-xs text-gray-500 border-b border-gray-500 border-dotted">
            {{ $t('templates.preview.configuration.readyStatus') }}
          </p>
        </div>
      </div>
    </div>
    <destroy-template-modal
      :template="templateInfo"
      :open="isDestroyModalOpen"
      @after-destroy="handleDestroy"
      @close="isDestroyModalOpen = false"
    />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import DestroyTemplateModal from './destroy-template-modal.vue';
import TipTapEditor from '../../components/shared/tiptap/tiptap.vue';

const SECONDS_IN_WEEK = 604800;

export default {
  components: { DestroyTemplateModal, TipTapEditor },
  props: {
    templateInfo: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  data() {
    return {
      showParticipants: false,
      showModerators: false,
      isDestroyModalOpen: false,
    };
  },
  computed: {
    template() {
      return this.templateInfo;
    },
    templateName() {
      return this.template.name;
    },
    templateRecurrent() {
      return this.template.recurrent;
    },
    templateWeeks() {
      return this.template.recurrenceDuration / SECONDS_IN_WEEK;
    },
    templateDate() {
      return moment(this.template.nextDate);
    },
    templateDay() {
      return this.t(`templates.form.weekDays.${this.templateDate.day()}`).toLowerCase();
    },
    templateHour() {
      return this.templateDate.format('HH:mm');
    },
    baseDocument() {
      return this.template.baseDocument;
    },
    participantsIds() {
      return this.baseDocument.participants;
    },
    participants() {
      return this.baseDocument.users.filter(
        (user) => this.participantsIds.includes(user.id.toString()),
      );
    },
    moderators() {
      const moderatorsIds = this.baseDocument.mastersOfCeremonies;

      return this.participants.filter((user) => moderatorsIds.includes(user.id));
    },
    sections() {
      return orderBy(this.baseDocument.sections, ['order'], ['asc']);
    },
    instructions() {
      return this.baseDocument.instructions;
    },
    configurations() {
      return this.baseDocument.documentConfiguration;
    },
  },
  methods: {
    openTemplateModal() {
      this.isDestroyModalOpen = true;
    },
    handleDestroy() {
      window.location.replace('..');
    },
  },
};
</script>
