<template>
  <div class="flex flex-col flex-1 overflow-hidden">
    <secondary-bar />
    <div class="flex flex-col items-center flex-1 p-4 overflow-scroll bg-slate-50">
      <div class="flex flex-col w-full lg:w-4/5">
        <backend-errors
          v-if="errors.length > 0"
          :errors="errors"
        >
          {{ $t('documents.edit.error') }}
        </backend-errors>
        <document-form
          class="flex-1 p-4 overflow-y-auto bg-white rounded-lg"
          :document="document"
          :team-members="teamMembers"
          :masters="masters"
          :user="user"
          @submit="updateDocument"
        />
      </div>
    </div>
    <loading-overlay v-if="loading" />
  </div>
</template>

<script>
import DocumentForm from '../components/documents/document-form.vue';
import BackendErrors from '../components/shared/backend-errors.vue';
import documentsApi from '../api/documents';
import LoadingOverlay from '../components/shared/loading-overlay.vue';
import SecondaryBar from '../components/shared/secondary-bar.vue';
import parseDocumentParams from '../helpers/documentParams';

export default {
  components: { DocumentForm, BackendErrors, LoadingOverlay, SecondaryBar },
  props: {
    document: {
      type: Object,
      default: null,
    },
    teamMembers: {
      type: Array,
      default: null,
    },
    masters: {
      type: Array,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { errors: [], loading: false, editedSections: 0 };
  },
  computed: {
    documentTitle() {
      return this.document.title;
    },
    documentId() {
      return this.document.id;
    },
    teamId() {
      return this.document.teamId;
    },
  },
  methods: {
    async updateDocument(formAttributes) {
      try {
        this.loading = true;
        const params = parseDocumentParams(this.teamId, formAttributes, this.document.sections);
        await documentsApi.updateDocument(this.document.id, params);
        window.location.replace(`/teams/${this.teamId}/documents/${this.documentId}`);
      } catch (error) {
        if (error.details) {
          this.errors = error.details;
        }
        this.loading = false;
      }
    },
  },
};
</script>
