import moment from 'moment';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import orderBy from 'lodash/orderBy';
import getDefaultDate from '../helpers/get-default-date';

function getSortedSections(document) {
  if (document.sections.length === 0) return [{ id: 1, title: '', order: 0 }];
  const mappedSections = document.sections.map(({ id, title, order }) => ({ id, title, order }));

  return orderBy(mappedSections, 'order');
}

function getDatetime(document) {
  const format = 'YYYY-MM-DDTHH:mm';
  if (document.datetime) return moment(document.datetime).format(format);

  return getDefaultDate();
}

function getDocumentConfigurations(document) {
  if (!document.documentConfiguration) {
    return {
      commentsAllowed: true,
      announcementsAllowed: true,
      readyStatusAllowed: true,
    };
  }

  return {
    commentsAllowed: document.documentConfiguration.commentsAllowed,
    announcementsAllowed: document.documentConfiguration.announcementsAllowed,
    readyStatusAllowed: document.documentConfiguration.readyStatusAllowed,
  };
}

function getDocumentParticipants(document, user) {
  if (document.participants && document.participants.length > 0) return document.participants;

  return [user];
}

export default function useDocumentForm({ document, masters, user, isForTemplate }) {
  const {
    title: documentTitle,
    videoUrl,
    instructions,
    access,
  } = document;

  const { values, meta } = useForm({
    initialValues: {
      title: documentTitle || '',
      datetime: getDatetime(document),
      videoUrl: videoUrl || '',
      access: access || 'team',
      participants: getDocumentParticipants(document, user),
      mastersOfCeremonies: masters || [user],
      instructions: instructions.body || '',
      sections: getSortedSections(document),
      documentConfigurationAttributes: getDocumentConfigurations(document),
    },
    validationSchema: yup.object({
      title: yup.string().when([], (schema) => (isForTemplate ? schema.optional() : schema.required())),
      datetime: yup.string().required(),
      videoUrl: yup.string().url(),
      participants: yup.array().min(1),
      mastersOfCeremonies: yup.array().min(1),
      sections: yup.array().of(yup.object({ title: yup.string().required() })),
    }),
  });

  return { values, meta };
}
