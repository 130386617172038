<template>
  <div>
    <mute-button
      icon-file-name="person-add.svg"
      :size="sm"
      @click="showInviteModal"
    >
      {{ $t('documents.invite.message') }}
    </mute-button>
    <base-modal
      :title="$t('documents.invite.invitation')"
      :open="isModalOpen"
      @close="closeInviteModal"
    >
      <h4 class="mb-4 text-base font-semibold md:text-lg">
        {{ $t('documents.invite.byLink') }}
      </h4>
      <div class="flex items-center h-20">
        <mute-input
          class="mr-4"
          :model-value="invitationUrl"
          readonly
          size="medium"
          @click="copyUrl"
        />
        <icon-button
          icon-file-name="copy-icon.svg"
          @click="copyUrl"
        />
      </div>
      <p
        class="text-sm text-green-500"
        :class="{ 'opacity-0': !copied }"
      >
        {{ $t('documents.invite.copy') }}
      </p>
      <div class="flex flex-row ">
        <mute-divider
          class="self-center h-2 mb-1.5 mr-3.5"
          color="gray-300"
          width="flex-grow"
        />
        <div class="self-center mx-4 text-gray-300">
          o
        </div>
        <mute-divider
          class="self-center h-2 mb-1.5 ml-3.5"
          color="gray-300"
          width="flex-grow"
        />
      </div>
      <div>
        <h4 class="mb-4 text-base font-semibold md:text-lg">
          {{ $t('documents.invite.byEmail') }}
        </h4>
        <h5 class="mb-4 text-sm">
          {{ $t('documents.invite.emailMessage') }}
        </h5>
        <div class="flex flex-row w-full">
          <input-tag
            v-model="tags"
            class="mr-4"
            :placeholder="$t('documents.invite.emailAdd')"
          />
          <mute-button
            icon-file-name="send.svg"
            @click="handleOnSend"
          >
            {{ $t('documents.invite.emailSend') }}
          </mute-button>
        </div>
        <div class="flex justify-between mt-2">
          <span
            class="text-sm"
            :class="{'opacity-0': !emailMessage, 'text-green-500': emailSent, 'text-red-500': emailError }"
          >
            {{ emailMessage }}
          </span>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
/* eslint-disable no-magic-numbers */
import { useI18n } from 'vue-i18n';
import MuteInput from '../shared/mute-input.vue';
import InputTag from '../shared/input-tag.vue';
import invitationApi from '../../api/invitations';

export default {
  components: { MuteInput, InputTag },
  props: {
    currentTeam: { type: Object, required: true },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  data() {
    return {
      copied: false,
      tags: [],
      emailSent: false,
      emailError: false,
      invitedEmails: [],
      isModalOpen: false,
    };
  },
  computed: {
    teamId() {
      return this.currentTeam.id;
    },
    invitationToken() {
      return this.currentTeam.invitationToken;
    },
    invitationUrl() {
      if (this.currentTeam) return this.currentTeam.invitationUrl;

      return null;
    },
    emails() {
      return this.tags.map(tag => tag.text);
    },
    emailMessage() {
      let message;
      if (this.emailSent) message = this.t('documents.invite.emailSent');
      else if (this.emailError) message = this.t('documents.invite.emailError');

      return message;
    },
  },
  watch: {
    tags() {
      this.emailSent = false;
    },
  },
  methods: {
    showInviteModal() {
      this.isModalOpen = true;
      this.emailError = false;
      this.emailSent = false;
    },
    closeInviteModal() {
      this.copied = false;
      this.isModalOpen = false;
    },
    async copyUrl() {
      await navigator.clipboard.writeText(this.invitationUrl);
      this.copied = true;
    },
    handleOnSend() {
      setTimeout(() => this.sendInvitation(), 10);
    },
    async sendInvitation() {
      try {
        await invitationApi.sendInvitation({
          emails: this.emails,
          teamId: this.teamId,
          invitationToken: this.invitationToken,
        });
        this.successfullInvitation();
      } catch (error) {
        this.emailError = true;
      }
    },
    successfullInvitation() {
      this.emailSent = true;
      this.emailError = false;
      this.invitedEmails = this.emails;
    },
  },
};
</script>
