<template>
  <vv-form
    v-slot="{ meta }"
    class="flex flex-col"
    action="/users"
    method="POST"
    :initial-values="{
      'user[username]': user.username || '',
      'user[email]': user.email || '',
      'user[password]': '',
      'user[password_confirmation]': '',
    }"
    :validation-schema="validationSchema"
  >
    <csrf-input />
    <mute-vv-input
      class="mb-6"
      :label="$t('sessions.signUp.fields.userName')"
      name="user[username]"
    />
    <mute-vv-input
      class="mb-6"
      :label="$t('sessions.signUp.fields.email')"
      name="user[email]"
    />
    <mute-vv-input
      class="mb-6"
      type="password"
      :label="$t('sessions.signUp.fields.password')"
      name="user[password]"
    />
    <mute-vv-input
      class="mb-6"
      type="password"
      :label="$t('sessions.signUp.fields.confirmPassword')"
      name="user[password_confirmation]"
    />
    <mute-cta-button
      :disabled="!meta.valid || !meta.dirty"
      type="submit"
    >
      {{ $t('sessions.signUp.endSignUp') }}
    </mute-cta-button>
  </vv-form>
</template>

<script>
import { Form as VvForm } from 'vee-validate';
import * as yup from 'yup';
import CsrfInput from '../shared/csrf-input.vue';
import MuteVvInput from '../shared/vee-validate/mute-vv-input.vue';
import { MINIMUM_PASSWORD_CHAR_LENGTH } from '../../utils/constants';

export default {
  components: { CsrfInput, MuteVvInput, VvForm },
  props: {
    user: { type: Object, required: true },
  },
  setup() {
    return {
      validationSchema: yup.object({
        'user[username]': yup.string().matches(/^[a-zA-Z0-9_.]*$/, 'custom.username').required(),
        'user[email]': yup.string().email().required(),
        'user[password]': yup.string().min(MINIMUM_PASSWORD_CHAR_LENGTH).required(),
        'user[password_confirmation]': yup.string().equalToSibling('user[password]', 'custom.passwordMatch'),
      }),
    };
  },
};
</script>
