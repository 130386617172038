<template>
  <div class="w-screen lg:w-auto">
    <div
      class="flex items-center justify-between px-2 text-left lg:pr-0 h-14 lg:h-auto lg:justify-start"
    >
      <div class="items-center hidden h-8 mr-auto text-slate-600 lg:flex">
        <a
          class="flex items-center"
          :href="goToHome"
          data-testid="documents-anchor"
        >
          <inline-svg
            class="fill-current mr-2 h-3 w-3"
            :src="require('../../../assets/images/icons/back-arrow-no-fill.svg')"
          />
          {{ $t('documents.goToHome') }}
        </a>
      </div>
      <div class="text-sm text-purple-500 lg:hidden">
        <div
          class="flex items-center"
          @click="$emit('change-mobile-modal')"
        >
          <inline-svg
            class="w-6 h-6 mr-2 text-purple-500 fill-current outline-white"
            :src="require('../../../assets/images/icons/hamburger-icon.svg')"
          />
          {{ documentName }}
        </div>
      </div>
      <div class="flex flex-row space-x-6">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    backUrl: {
      type: String,
      default: '',
    },
    documentName: {
      type: String,
      default: '',
    },
    documentId: {
      type: Number,
      default: null,
    },
    editDocumentId: {
      type: Number,
      default: null,
    },
    pageTitle: {
      type: String,
      default: '',
    },
  },
  emits: ['change-mobile-modal'],
  computed: {
    goToHome() {
      if (this.backUrl !== '') {
        return this.backUrl;
      }

      const currentUrl = window.location.href;
      if (currentUrl.includes('documents')) {
        const applicationHostRegex = /^[^:]+:\/\/[^/]+/;
        const teamHomeUrl = window.location.href.split('documents')[0];

        return teamHomeUrl.replace(applicationHostRegex, '');
      }

      return '/';
    },
  },
};
</script>
