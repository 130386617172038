const initialState = {
  hiddenAnnouncements: {},
  hiddenSections: {},
};

const actions = {
  resetHiddenAnnouncements({ commit }) {
    commit('emptyHiddenAnnouncements');
  },
  toggleHiddenSection({ commit }, newValue) {
    commit('setHiddenSections', newValue);
  },
  toggleHiddenAnnouncement({ commit }, newValue) {
    commit('setHiddenAnnouncements', newValue);
  },
};

const mutations = {
  emptyHiddenAnnouncements(state) {
    state.hiddenAnnouncements = {};
  },
  setHiddenSections(state, { isVisible, index, isOnTop }) {
    if (isVisible) {
      delete state.hiddenSections[index];
    } else {
      state.hiddenSections[index] = { isOnTop };
    }
  },
  setHiddenAnnouncements(state, { isVisible, index, isOnTop }) {
    if (isVisible) {
      delete state.hiddenAnnouncements[index];
    } else {
      state.hiddenAnnouncements[index] = { isOnTop };
    }
  },
};

export default {
  state: initialState,
  actions,
  mutations,
};
