<template>
  <div
    class="absolute top-0 left-0 z-50 flex w-full h-full bg-white"
  >
    <inline-svg
      class="text-purple-500 fill-current m-auto"
      :src="require('assets/images/icons/loader.svg')"
    />
  </div>
</template>
