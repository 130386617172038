<template>
  <base-modal @close="updateFeaturesSeenAt">
    <tab-content
      :tab-names="{recentChangelogs: $t('changelog.recentChangelogs') , allChangelogs: $t('changelog.allChangelogs')}"
      class="flex flex-col flex-1"
    >
      <template #recentChangelogs>
        <changelog-tab
          :username="user.username"
          type="recent"
          :changelog-items-current="changelogItems"
        />
      </template>
      <template #allChangelogs>
        <changelog-tab
          type="all"
          :username="user.username"
        />
      </template>
    </tab-content>

    <div class="flex flex-row justify-end mt-4">
      <mute-button
        variant="purple"
        @click="updateFeaturesSeenAt"
      >
        {{ $t('changelog.close') }}
      </mute-button>
    </div>
  </base-modal>
</template>

<script>
import { mapState } from 'vuex';
import usersApi from '../../api/users';
import TabContent from '../shared/tab-content.vue';
import ChangelogTab from '../changelog/changelog-tab.vue';

export default {
  components: { TabContent, ChangelogTab },
  props: {
    changelogItems: { type: Array, required: false, default: null },
  },
  emits: ['update', 'close'],
  data() {
    return {
      loading: false,
      errors: '',
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    async updateFeaturesSeenAt() {
      const oldFeaturesSeenAt = this.user.featuresSeenAt;
      const newDate = new Date();
      this.$store.dispatch('setFeaturesSeenAt', newDate);
      usersApi.update({ user: { featuresSeenAt: newDate.toString() } })
        .then(() => {
          this.$emit('update');
        })
        .catch(() => {
          this.$store.dispatch('setFeaturesSeenAt', oldFeaturesSeenAt);
        })
        .finally(() => {
          this.$emit('close');
        });
    },
  },
};
</script>
