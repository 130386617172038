import { pickBy } from 'lodash';
import { getSectionIdFromAnnouncementId } from './helpers';

const initialState = {
  mutedSections: {},
  mutedAnnouncements: {},
  announcementsNotificated: [],
  commentsNotificated: [],
  notificationsAllowed: true,
};

const getters = {
  taggedMessages(state, documentGetters) {
    return pickBy(state.announcements, (announcement) => {
      const announcementMessage = announcement.body;
      const commentsIds = Object.values(announcement.comments);
      const commentsMessages = commentsIds.map((id) => state.comments[id].body);
      const allMessages = [announcementMessage, ...commentsMessages];

      return allMessages.some((message) => documentGetters.isUsernameInMessage(message));
    });
  },
};

const actions = {
  toggleNotification({ commit }, newValue) {
    commit('setMutedNotifications', newValue);
  },
  updateNotifications({ commit }, payload) {
    commit(`update${payload.type}Notificated`, payload.object);
  },
};

const mutations = {
  setMutedNotifications(state, { type, index, notification }) {
    if (notification) {
      state[type][index] = notification;
    } else {
      delete state[type][index];
    }
  },
  updateCommentsNotificated(state, object) {
    const sectionId = getSectionIdFromAnnouncementId(state.sections, object.announcementId);
    state.commentsNotificated.push({ sectionId, announcementId: String(object.announcementId) });
  },
  updateAnnouncementsNotificated(state, object) {
    state.announcementsNotificated.push({ sectionId: String(object.sectionId) });
  },
  setNotificationsAllowed(state, payload) {
    state.notificationsAllowed = payload;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
