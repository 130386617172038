<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div
    id="section-input-groups"
    class="flex-1 mb-4"
  >
    <draggable
      id="draggable-list"
      :list="sortedSections"
      handle=".drag-handle"
      animation="150"
      draggable=".draggable-item"
      item-key="order"
      @change="$emit('move-section')"
    >
      <template #item="{ element: section, index }">
        <div
          :id="`section-input-group-${index}`"
          class="draggable-item flex items-center col-span-2"
          :class="{ 'mb-3': index < sortedSections.length - 1 }"
        >
          <div
            class="flex flex-col self-start justify-center w-10 h-16"
          >
            <div
              v-if="sortedSections.length !== 0"
              class="drag-handle w-5 h-8 col-span-1 text-gray-500 rounded cursor-move justify-self-end"
            >
              <inline-svg
                :src="require(`assets/images/icons/drag.svg`)"
                class="grow-0 mx-auto fill-current"
              />
            </div>
          </div>
          <mute-vv-input
            type="text"
            :label="$t('documents.easyCreate.fields.sectionNumber', { index: index + 1 })"
            :name="`sections[${index}].title`"
            class="flex-1"
            :class="{ 'mr-2': index !== 0 }"
            :focused-on-mount="index > 0"
            @keypress.enter="$emit('add-section')"
          />
          <mute-button
            v-if="index !== 0"
            :id="`delete-button-${index}`"
            class="hidden md:block"
            icon-file-name="close-icon.svg"
            variant="red"
            outlined
            @click="$emit('delete-section', index)"
          />
          <mute-button
            v-if="index !== 0"
            :id="`delete-button-${index}`"
            class="block md:hidden"
            icon-file-name="close-icon.svg"
            variant="red"
            outlined
            size="sm"
            @click="$emit('delete-section', index)"
          />
        </div>
      </template>
    </draggable>
    <div class="flex justify-end mt-3">
      <mute-button
        class="hidden md:block"
        variant="green"
        outlined
        icon-file-name="add.svg"
        @click="$emit('add-section')"
      />
      <mute-button
        class="block md:hidden"
        variant="green"
        outlined
        icon-file-name="add.svg"
        size="sm"
        @click="$emit('add-section')"
      />
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';

export default {
  components: { draggable },
  props: {
    sortedSections: {
      type: Array,
      required: true,
    },
  },
  emits: ['move-section', 'add-section', 'delete-section', 'delete-section', 'add-section', 'add-section'],
};

</script>
