<template>
  <div
    class="flex items-center"
    :class="$attrs.class"
  >
    <input
      v-bind="filteredAttrs"
      :id="id"
      type="checkbox"
      class="hidden"
      :value="checked"
      @input="$emit('update:modelValue', !modelValue)"
    >
    <label
      :for="id"
      class="transition-colors block w-4 h-4 mr-2 text-white duration-100 ease-in-out rounded cursor-pointer"
      :class="checked ? 'bg-purple-500' : 'bg-gray-300'"
    >
      <inline-svg
        :src="require('../../../assets/images/icons/check-icon.svg')"
        class="w-full h-full transition-opacity duration-100 ease-in-out opacity-0 fill-current"
        :class="checked && 'opacity-100'"
      />
    </label>
    <label
      v-if="label"
      :for="id"
      class="flex-1 text-sm cursor-pointer select-none"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import useFilteredAttrs from '../../composables/useFilteredAttrs';

export default {
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    label: { type: String, default: null },
    modelValue: { type: Boolean, required: true },
  },
  emits: ['update:modelValue'],
  setup() {
    const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

    return { filteredAttrs };
  },
  computed: {
    checked() {
      return this.modelValue;
    },
  },
};
</script>
