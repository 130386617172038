import i18n from '../plugins/i18n';

const initialState = {
  id: null,
  avatar: null,
  username: null,
  email: null,
  featuresSeenAt: null,
  locale: null,
};

const actions = {
  setUser({ commit }, user) {
    commit('setId', user.id);
    commit('setAvatar', user.avatar);
    commit('setUsername', user.username);
    commit('setEmail', user.email);
    commit('setFeaturesSeenAt', new Date(user.featuresSeenAt));
    commit('setLocale', user.locale);
  },
  setUserId({ commit }, userId) {
    commit('setId', userId);
  },
  setAvatar({ commit }, userAvatar) {
    commit('setAvatar', userAvatar);
  },
  setUsername({ commit }, userName) {
    commit('setUsername', userName);
  },
  setFeaturesSeenAt({ commit }, userFeaturesSeenAt) {
    commit('setFeaturesSeenAt', userFeaturesSeenAt);
  },
  setLocale({ commit }, userLocale) {
    commit('setLocale', userLocale);
  },
};

const mutations = {
  setId(state, payload) {
    state.id = payload;
  },
  setAvatar(state, payload) {
    state.avatar = payload;
  },
  setUsername(state, payload) {
    state.username = payload;
  },
  setEmail(state, payload) {
    state.email = payload;
  },
  setFeaturesSeenAt(state, payload) {
    state.featuresSeenAt = payload;
  },
  setLocale(state, payload) {
    i18n.locale = payload;
    state.locale = payload;
  },
};

export default {
  state: { ...initialState },
  actions,
  mutations,
};
