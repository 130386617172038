<template>
  <div class="grid grid-cols-4 gap-4">
    <div class="grid content-start grid-cols-2 gap-4 col-span-full lg:col-span-2">
      <div>
        <p
          class="col-span-2 mb-2 text-lg md:mb-4"
        >
          {{ $t('documents.information') }}
        </p>
        <mute-divider />
      </div>
      <mute-vv-input
        v-if="!isForTemplate"
        id="title-input"
        type="text"
        :label="$t('documents.create.fields.title')"
        name="title"
        class="col-span-full"
      />
      <mute-vv-input
        v-if="!isForTemplate"
        type="datetime-local"
        :label="$t('documents.create.fields.dateTime')"
        class="col-span-full"
        name="datetime"
      />
      <mute-vv-input
        id="video-url-input"
        type="text"
        :label="$t('documents.create.fields.meetLink')"
        class="col-span-full"
        name="videoUrl"
      />
      <div class="col-span-full">
        <div class="flex items-center mb-2 space-x-1">
          <p class="text-gray-600">
            {{ $t('documents.create.access') }}
          </p>
          <inline-svg
            v-tooltip="$t('documents.create.accessHint')"
            class="w-4 h-4 fill-current"
            :src="require('assets/images/icons/info-icon.svg')"
          />
        </div>
        <div
          class="space-y-2 md:flex md:space-y-0"
          :class="values.access !== 'team' && 'mb-2'"
        >
          <mute-vv-radio
            class="md:w-1/2"
            :label="$t('documents.create.fields.access.team')"
            name="access"
            value="team"
          />
          <mute-vv-radio
            class="md:w-1/2"
            :label="$t('documents.create.fields.access.invite')"
            name="access"
            value="invite"
          />
        </div>
        <mute-vv-multiselect
          v-if="values.access !== 'team'"
          v-bind="commonMultiselectProps"
          name="participants"
          :options="teamMembers"
          :placeholder="$t('documents.create.fields.access.select')"
          :label="$t('documents.create.fields.access.label')"
        />
      </div>
      <div class="col-span-full">
        <div class="flex items-center mb-2 text-gray-600">
          <p class="mr-2">
            {{ $t('documents.create.mastersOfCeremony') }}
          </p>
          <inline-svg
            v-tooltip="$t('documents.create.masterOfCeremonyInfo')"
            class="w-4 h-4 fill-current"
            :src="require('assets/images/icons/info-icon.svg')"
          />
        </div>
        <mute-vv-multiselect
          v-bind="commonMultiselectProps"
          name="mastersOfCeremonies"
          :options="eligiblesForMasterOfCeremony"
          :placeholder="$t('documents.create.fields.mastersOfCeremony.select')"
          :label="$t('documents.create.fields.mastersOfCeremony.label')"
        />
      </div>
      <div class="col-span-full">
        <div class="flex items-center my-2 text-gray-600">
          <p class="mr-2">
            {{ $t('documents.create.configurations') }}
          </p>
          <inline-svg
            v-tooltip="$t('documents.create.configurationsInfo')"
            class="w-4 h-4 fill-current"
            :src="require('assets/images/icons/info-icon.svg')"
          />
        </div>
        <div class="md:flex-wrap md:flex">
          <mute-vv-checkbox
            id="checkbox-announcements"
            class="mb-2 md:mr-2"
            :label="$t('documents.create.fields.configurations.announcementsLabel')"
            name="documentConfigurationAttributes.announcementsAllowed"
          />
          <mute-vv-checkbox
            id="checkbox-comments"
            class="mb-2 md:mr-2"
            :label="$t('documents.create.fields.configurations.commentsLabel')"
            name="documentConfigurationAttributes.commentsAllowed"
          />
          <mute-vv-checkbox
            id="checkbox-ready-status"
            class="md:mr-2 md:mb-2"
            :label="$t('documents.create.fields.configurations.readyStatusLabel')"
            name="documentConfigurationAttributes.readyStatusAllowed"
          />
        </div>
      </div>
      <div class="text-gray-600 col-span-full">
        <div class="flex items-center my-2">
          <p class="mr-2">
            {{ $t('documents.create.fields.instructions.label') }}
          </p>
          <inline-svg
            v-tooltip="$t('documents.create.fields.instructions.info')"
            class="w-4 h-4 fill-current"
            :src="require('assets/images/icons/info-icon.svg')"
          />
        </div>
        <tip-tap-vv-editor
          name="instructions"
          bordered
          input-id="add-instructions"
          :disabled-extensions="['Image', 'Tag', 'Mention']"
          :hidden-buttons="['attach']"
          :placeholder="$t('documents.create.fields.instructions.placeholder')"
        />
      </div>
    </div>
    <div class="flex flex-col col-span-full lg:col-span-2">
      <div class="flex items-center justify-between mb-2 md:mb-4">
        <p class="text-lg">
          {{ $t('documents.create.fields.sections') }}
        </p>
      </div>
      <mute-divider class="mb-4" />
      <sections-form
        :sorted-sections="values.sections"
        @add-section="addSection"
        @delete-section="deleteSection"
        @move-section="moveSection"
      />
      <mute-button
        class="w-full col-span-4 ml-auto md:w-auto"
        :disabled="!meta.valid || externalValidation"
        @click="handleSubmit"
      >
        {{ document.id ? $t('documents.create.save') : $t('documents.create.create') }}
      </mute-button>
    </div>
  </div>
</template>

<script>
import MuteDivider from '../shared/mute-divider.vue';
import SectionsForm from './sections-form.vue';
import TipTapVvEditor from '../shared/vee-validate/tip-tap-vv-editor.vue';
import useDocumentForm from '../../composables/useDocumentForm';

export default {
  components: { MuteDivider, SectionsForm, TipTapVvEditor },
  props: {
    document: {
      type: Object,
      default: null,
    },
    teamMembers: {
      type: Array,
      default: null,
    },
    masters: {
      type: Array,
      default: null,
    },
    isForTemplate: {
      type: Boolean,
      default: false,
    },
    externalValidation: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ['submit'],
  setup(props) {
    const { meta, values } = useDocumentForm({
      document: props.document,
      masters: props.masters,
      user: props.user,
      isForTemplate: props.isForTemplate,
    });

    return { meta, values };
  },
  computed: {
    eligiblesForMasterOfCeremony() {
      return this.values.access === 'team' ? this.teamMembers : this.values.participants;
    },
    commonMultiselectProps() {
      return {
        multiple: true,
        closeOnSelect: false,
        clearOnSelect: false,
        preserveSearch: true,
        searchable: true,
        customLabel({ username, email }) {
          return `${username} (${email})`;
        },
        trackBy: 'email',
      };
    },
  },
  methods: {
    addSection() {
      this.values.sections.push({
        id: Math.max(...this.values.sections.map(section => section.id), 0) + 1,
        title: '',
        order: this.values.sections.length,
      });
    },
    deleteSection(sectionIndex) {
      this.values.sections.splice(sectionIndex, 1);
      this.values.sections.forEach((section) => {
        section.order = this.values.sections.indexOf(section);
      });
    },
    moveSection() {
      this.values.sections.forEach(section => {
        section.order = this.values.sections.indexOf(section);
      });
    },
    handleSubmit() {
      this.$emit('submit', {
        ...this.values,
        mastersOfCeremonies: this.values.mastersOfCeremonies.map((user) => user.id),
      });
    },
  },
};
</script>
