<template>
  <div class="h-screen py-20 bg-slate-50">
    <div
      class="flex flex-col w-11/12 h-full mx-auto overflow-hidden bg-white rounded-lg shadow-md md:flex-row lg:w-5/6 xl:w-2/3"
    >
      <div class="relative w-full h-32 text-white bg-purple-500 md:h-full md:w-6/12">
        <div class="absolute inset-0 flex flex-col items-center justify-center">
          <inline-svg
            class="h-12 mb-6 fill-current md:h-32"
            :src="require('assets/images/logo.svg')"
          />
          <mute-divider color="white" />
          <p class="text-xl md:text-3xl">
            {{ $t('sessions.silentMeetings') }}
          </p>
        </div>
        <div class="absolute inset-0 z-0">
          <inline-svg
            :src="require('../../../assets/images/auth-pattern.svg')"
            class="w-full h-full"
          />
        </div>
      </div>
      <div class="flex flex-col grow w-full px-8 py-6 overflow-y-auto md:grow-0 md:px-16 md:w-6/12">
        <div class="flex flex-col justify-center grow mb-2">
          <div class="flex items-center mb-6 text-purple-500">
            <div
              v-if="showBackButton"
              class="flex items-center h-full mr-2 cursor-pointer"
              @click="handleBackClick"
            >
              <inline-svg
                :src="require('../../../assets/images/icons/left-arrow.svg')"
                class="fill-current"
              />
            </div>
            <p class="text-3xl leading-none">
              {{ header }}
            </p>
          </div>
          <mute-divider class="mb-6" />
          <slot name="content" />
        </div>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: { type: String, required: true },
    backButtonHref: { type: String, default: null },
    showBackButton: { type: Boolean, default: false },
  },
  emits: ['back-click'],
  methods: {
    handleBackClick() {
      if (this.backButtonHref) {
        window.location.replace(this.backButtonHref);
      } else {
        this.$emit('back-click');
      }
    },
  },
};
</script>
