<template>
  <div>
    <div
      v-if="teamDocuments.length === 1"
      class="flex items-center justify-center mb-2 font-bold text-green-400"
    >
      <inline-svg
        :src="require('../../../assets/images/icons/down-arrow.svg')"
        class="w-3 h-3 mr-1 fill-current"
      />
      <p class="mx-3">
        {{ $t("documents.firstDocument") }}
      </p>
      <inline-svg
        :src="require('../../../assets/images/icons/down-arrow.svg')"
        class="w-3 h-3 mr-1 fill-current"
      />
    </div>
    <div
      v-for="(document, index) in sortedDocuments"
      :key="document.id"
      class="flex h-32"
      :class="{
        'mb-32': index === sortedDocuments.length - 1,
        'border-b-2 border-gray-300  hover:bg-gray-300':
          virtualDocuments.length !== 1,
        'border-2 border-green-400 rounded-md hover:bg-green-100 mx-2':
          teamDocuments.length === 1 && index === 0,
        'mt-1 border-2 border-purple-400 rounded-md hover:bg-purple-100 mx-2':
          document.visibility === 'introduction',
      }"
    >
      <a
        :href="documentLink(document)"
        class="flex justify-between w-full"
      >
        <div class="flex flex-col my-auto ml-8">
          <p class="text-3xl">
            {{ document.title }}
          </p>
          <p class="text-gray-600">
            {{ documentDate(document) }}
          </p>
        </div>
      </a>
      <div class="flex justify-end py-2 mr-8">
        <a
          v-if="isMasterOfCeremonies(document)"
          class="flex flex-col items-center justify-center mr-2 opacity-50 hover:opacity-100 rounded-xl focus:outline-none"
          :href="`/teams/${document.teamId}/documents/${document.id}/edit`"
        >
          <img src="../../../assets/images/icons/edit.svg">
          <p class="text-xs">{{ $t("documents.table.edit") }}</p>
        </a>
        <a
          v-if="ENABLE_DUPLICATE_DOCUMENT == 'true'"
          class="flex flex-col items-center justify-center mr-2 opacity-50 hover:opacity-100 rounded-xl focus:outline-none"
          :href="`/teams/${document.teamId}/documents/new?ref_id=${document.id}`"
        >
          <img src="../../../assets/images/icons/copy-icon-black.svg">
          <p class="text-xs">{{ $t("documents.table.copy") }}</p>
        </a>
        <a
          class="flex items-center mr-2 opacity-50 hover:opacity-100 rounded-xl focus:outline-none"
          :href="`/teams/${document.teamId}/templates/new?ref_id=${document.id}`"
        >
          <div
            v-tooltip="$t('documents.table.templateTooltip')"
            class="flex flex-col items-center"
          >
            <img
              src="../../../assets/images/icons/template.svg"
            >
            <p class="text-xs">{{ $t("documents.table.template") }}</p>
          </div>
        </a>
        <button
          v-if="isMasterOfCeremonies(document)"
          class="flex flex-col items-center justify-center mr-2 opacity-50 hover:opacity-100 rounded-xl focus:outline-none"
          @click="openDocumentModal(document)"
        >
          <img src="../../../assets/images/icons/delete.svg">
          <p class="text-xs">
            {{ $t("documents.table.delete") }}
          </p>
        </button>
      </div>
    </div>
    <destroy-document-modal
      :document="selectedDocument"
      :open="isDestroyDocModalOpen"
      @destroyed-document="filterDestroyedDocument"
      @before-close="selectedDocument = null"
      @close="isDestroyDocModalOpen = false"
    />
  </div>
</template>

<script>
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import DestroyDocumentModal from './destroy-document-modal.vue';

const ENABLE_DUPLICATE_DOCUMENT = process.env.ENABLE_DUPLICATE_DOCUMENT;

export default {
  components: { DestroyDocumentModal },
  props: {
    documents: { type: Array, required: true },
    hostedDocumentIds: { type: Array, required: true },
    criteria: { type: String, required: true },
    order: { type: String, required: true },
  },
  data() {
    return {
      sortCriteria: this.criteria,
      descending: this.order === 'descending',
      selectedDocument: null,
      isDestroyDocModalOpen: false,
      virtualDocuments: [...this.documents],
    };
  },
  computed: {
    sortedDocuments() {
      return orderBy(
        this.formattedDocuments,
        (document) => document[this.sortCriteria],
        this.sortType,
      );
    },
    formattedDocuments() {
      return map(this.virtualDocuments, (document) => ({
        ...document,
        date: moment(document.datetime),
      }));
    },
    teamDocuments() {
      return this.formattedDocuments.filter(
        (document) => document.visibility !== 'introduction',
      );
    },
    sortType() {
      return this.descending ? 'desc' : 'asc';
    },
    user() {
      return this.$store.state.user.id;
    },
  },
  watch: {
    criteria() {
      this.sortCriteria = this.criteria;
    },
    order() {
      this.descending = this.order === 'descending';
    },
  },
  created() {
    this.ENABLE_DUPLICATE_DOCUMENT = ENABLE_DUPLICATE_DOCUMENT;
  },
  methods: {
    documentLink(document) {
      return `/teams/${document.teamId}/documents/${document.id}`;
    },
    handleSort(criteria) {
      if (this.sortCriteria === criteria) {
        this.descending = !this.descending;
      } else {
        this.sortCriteria = criteria;
      }
    },
    openDocumentModal(document) {
      this.isDestroyDocModalOpen = true;
      this.selectedDocument = document;
    },
    filterDestroyedDocument(documentId) {
      this.virtualDocuments = this.virtualDocuments.filter(
        ({ id }) => id !== documentId,
      );
    },
    documentDate(document) {
      return moment(document.date)
        .locale(this.$i18n.locale)
        .format('ddd DD MMM hh:mm a');
    },
    isMasterOfCeremonies(document) {
      return this.hostedDocumentIds.includes(document.id);
    },
  },
};
</script>
