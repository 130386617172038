import api from './index';

export default {
  updateDocumentConfiguration(documentConfigurationId, body) {
    return api({
      method: 'patch',
      url: `/api/internal/document_configurations/${documentConfigurationId}`,
      data: body,
    });
  },
};
