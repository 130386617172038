import api from './index';

export default {
  getTeams() {
    return api({
      method: 'get',
      url: '/api/internal/teams',
    });
  },
  createTeam(body) {
    return api({
      method: 'post',
      url: '/api/internal/teams',
      data: body,
    });
  },
  updateTeam(body, id) {
    return api({
      method: 'put',
      url: `/api/internal/teams/${id}`,
      data: body,
    });
  },
  batchDestroyUsersFromTeam(body, id) {
    return api({
      method: 'delete',
      url: `/api/internal/teams/${id}/memberships/batch_destroy`,
      data: body,
    });
  },
  removeRoleFromUser(teamId, userId, roleName) {
    return api({
      method: 'delete',
      url: `/api/internal/teams/${teamId}/users/${userId}/roles/${roleName}`,
    });
  },
  addRoleToUser(teamId, userId, roleName) {
    return api({
      method: 'post',
      url: `/api/internal/teams/${teamId}/users/${userId}/roles`,
      data: {
        roleName,
      },
    });
  },
};
