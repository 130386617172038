import { assignIn, cloneDeep, pickBy } from 'lodash';
import { tagIntersection } from './helpers';

const initialState = {
  filterTaggedMessages: false,
  filterCreatedAnnouncements: false,
  filterCreatedComments: false,
  filterTags: [],
};

const getters = {
  anyFilterToggled(state) {
    let anyFilterToggled = state.filterTaggedMessages || state.filterCreatedAnnouncements;
    anyFilterToggled = anyFilterToggled || state.filterCreatedComments || state.filterTags.length > 0;

    return anyFilterToggled;
  },
  filteredAnnouncementsByTags: (state) => (tagList) => pickBy(state.announcements, tagIntersection(tagList)),
  filteredAnnouncements: (state, documentGetters) => {
    let announcements = {};

    if (state.filterTaggedMessages) {
      announcements = assignIn(announcements, documentGetters.taggedMessages);
    }
    if (state.filterCreatedAnnouncements) {
      announcements = assignIn(announcements, documentGetters.createdAnnouncements);
    }
    if (state.filterCreatedComments) {
      announcements = assignIn(announcements, documentGetters.createdComments);
    }
    if (state.filterTags.length > 0) {
      announcements = assignIn(announcements, documentGetters.filteredAnnouncementsByTags(state.filterTags));
    }

    return announcements;
  },
  filteredSectionAnnouncements(state, documentGetters) {
    const newSections = cloneDeep(state.sections);
    Object.values(newSections).forEach((section) => {
      const announcements = section.announcements;
      const filtered = announcements.filter((announcement) => announcement in documentGetters.announcements);
      section.announcements = filtered;
    });

    return newSections;
  },
};

const mutations = {
  toggleTagFilter(state) {
    state.filterTaggedMessages = !state.filterTaggedMessages;
  },
  toggleCreatedAnnouncementsFilter(state) {
    state.filterCreatedAnnouncements = !state.filterCreatedAnnouncements;
  },
  toggleCreatedCommentsFilter(state) {
    state.filterCreatedComments = !state.filterCreatedComments;
  },
  setTagsFilter(state, newTags) {
    state.filterTags = newTags;
  },
};

export default {
  state: initialState,
  getters,
  mutations,
};
