export default function getPreloadableUrls(array) {
  const bucketName = process.env.S3_BUCKET;
  const prefix = `https://s3.amazonaws.com/${bucketName}`;
  const regex = new RegExp(`${prefix.replace(/\./g, '\\.')}/[^\\s"]+`, 'g');
  const urls = array.flatMap(string =>
    Array.from(string.matchAll(regex)).map(match => match[0]),
  );

  return Array.from(new Set(urls));
}
