<template>
  <session-wrapper
    :show-back-button="true"
    back-button-href="/sessions"
    :header="$t('sessions.passwordRecovery.message')"
  >
    <template #content>
      <slot name="errors" />
      <vv-form
        v-slot="{ meta }"
        action="/users/password"
        method="post"
        class="flex flex-col"
        :initial-values="{ 'user[email]': '' }"
        :validation-schema="validationSchema"
      >
        <p class="mb-6">
          {{ $t('sessions.passwordRecovery.email.message') }}
        </p>
        <csrf-input />
        <mute-vv-input
          class="mb-6"
          :label="$t('sessions.passwordRecovery.email.address')"
          type="text"
          name="user[email]"
        />
        <mute-cta-button
          type="submit"
          :disabled="!meta.valid || !meta.dirty"
        >
          {{ $t('sessions.passwordRecovery.email.send') }}
        </mute-cta-button>
      </vv-form>
    </template>
    <template #footer>
      <session-footer-link href="/users/sign_in">
        {{ $t('sessions.login') }}
      </session-footer-link>
    </template>
  </session-wrapper>
</template>

<script>
import { Form as VvForm } from 'vee-validate';
import * as yup from 'yup';
import SessionFooterLink from '../components/session/session-footer-link.vue';
import SessionWrapper from '../components/session/session-wrapper.vue';
import CsrfInput from '../components/shared/csrf-input.vue';
import MuteVvInput from '../components/shared/vee-validate/mute-vv-input.vue';

export default {
  components: { SessionWrapper, CsrfInput, SessionFooterLink, MuteVvInput, VvForm },
  setup() {
    return {
      validationSchema: yup.object({
        'user[email]': yup.string().email().required(),
      }),
    };
  },
};
</script>
