<template>
  <div
    class="flex items-center"
  >
    <div
      v-if="readyStatusAllowed"
      class="flex"
    >
      <div
        class="flex items-center space-x-2"
        data-testId="ready-status-wrapper"
      >
        <inline-svg
          class="w-4 h-4 text-center text-teal-600"
          :src="require(`assets/images/icons/happy-face.svg`)"
        />
        <p class="text-teal-600">
          {{ (`${usersReady}/${usersInSession} ${$tc('meetings.ready.usersReady', usersReady)}`) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    document() {
      return this.$store.state.document;
    },
    documentConfiguration() {
      return this.document.documentConfiguration;
    },
    readyStatusAllowed() {
      return this.documentConfiguration && this.documentConfiguration.readyStatusAllowed;
    },
    usersReady() {
      const documentId = this.document.document && this.document.document.id;
      if (!documentId || !this.document.usersReady[documentId]) {
        return 0;
      }

      return Object.values(this.document.usersReady[documentId]).filter(Boolean).length;
    },
    usersInSession() {
      return this.document.document && this.document.document.participants.length;
    },
  },
};
</script>
