/* eslint-disable no-undef */
const { slate, indigo, rose } = require('tailwindcss/colors');

const customGreen = {
  '100': '#e6f6f2',
  '200': '#bfe9dd',
  '300': '#99dcc9',
  '400': '#4dc2a1',
  '500': '#00a878',
  '600': '#00976c',
  '700': '#007e5a',
  '800': '#006548',
  '900': '#00523b',
};

module.exports = {
  theme: {
    extend: {
      zIndex: {
        '-1': -1,
      },
      colors: {
        black: '#040F25',
        gray: slate,
        purple: indigo,
        green: customGreen,
        red: rose,
      },
      minHeight: {
        '16': '4rem',
        '24': '6rem',
      },
      minWidth: {
        '16': '4rem',
        '24': '6rem',
      },
      width: {
        '98': '30rem',
      },
      typography: {
        DEFAULT: {
          css: {
            h1: {
              '@apply text-xl font-bold text-purple-500': {},
            },
            h2: {
              '@apply text-lg font-bold text-purple-500': {},
            },
            h3: {
              '@apply text-base font-bold text-purple-500': {},
            },
            h4: {
              '@apply text-sm font-bold text-purple-500': {},
            },
            h5: {
              '@apply text-xs font-bold text-purple-500': {},
            },
            h6: {
              '@apply text-xs font-bold text-black': {},
            },
            p: {
              '@apply text-sm text-black': {},
            },
            em: {
              '@apply text-sm text-black': {},
            },
            strong: {
              '@apply text-sm text-black': {},
            },
            blockquote: {
              '@apply border-l-4 border-purple-500': {},
            },
            a: {
              '@apply text-purple-500 text-sm': {},
            },
            'ul > li::before': {
              '@apply bg-purple-500': {},
            },
            'ol > li::before': {
              '@apply text-purple-500': {},
            },
            'code': {
              '@apply text-sm text-purple-500 font-normal p-1 bg-gray-100 rounded-sm': {},
            },
            'code::before': {
              content: '""',
            },
            'code::after': {
              content: '""',
            },
            pre: {
              '@apply text-purple-500 font-normal text-sm bg-gray-100': {},
            },
            '.highlight': {
              '@apply  bg-gray-100 p-1 rounded-sm': {},
            },
            hr: {
              '@apply text-gray-400 border-t': {},
            },

          },
        },
      },
    },
  },
  variants: {
    borderWidth: ['responsive', 'focus'],
    display: ['responsive', 'group-hover'],
    extends: {
      border: ['last'],
      rounded: ['last', 'first'],
    },
  },
  plugins: [require('@tailwindcss/typography')],
  content: [
    './app/**/*.html',
    './app/**/*.vue',
    './app/**/*.js',
    './app/**/*.erb',
  ],
};
