import { pickBy } from 'lodash';
import { normalize } from 'normalizr';

import schemas from '../../schemas';
import {
  getSavedAnnouncementIndex,
  removeSavedAnnouncement,
  addSavedAnnouncement,
} from './helpers';

const initialState = {
  announcements: {},
  comments: {},
  reactions: {},
  savedAnnouncementsByDocument: {},
  draftComments: {},
};

const getters = {
  draftComment: (state) => (threadId) => {
    const draft = state.draftComments[threadId];
    if (!draft) return '';

    return draft.content;
  },
  draftCommentsCount: (state) => () => (
    Object.values(state.draftComments).filter((text) => text).length
  ),
  createdAnnouncements(state, _documentGetters, rootState) {
    return pickBy(state.announcements, (announcement) => announcement.author.email === rootState.user.email);
  },
  createdComments(state, _documentGetters, rootState) {
    return pickBy(state.announcements, (announcement) => {
      const commentsIds = Object.values(announcement.comments);
      const commentsAuthors = commentsIds.map((id) => state.comments[id].author.email);

      return commentsAuthors.some((author) => author === rootState.user.email);
    });
  },
  announcements(state, documentGetters) {
    if (!documentGetters.anyFilterToggled) {
      return state.announcements;
    }

    return documentGetters.filteredAnnouncements;
  },
};

const actions = {
  createAnnouncement({ commit, rootState, dispatch }, announcement) {
    const sectionId = announcement.sectionId.toString();
    commit('updateAnnouncement', announcement);
    commit('addAnnouncement', { announcement, sectionId });
    dispatch('setTagList', announcement.tagList, { root: true });
    if (rootState.user.id !== announcement.author.id) {
      commit('updateUnreadAnnouncements', {
        announcementId: announcement.id.toString(),
        sectionId,
        type: 'increase' },
      );
    }
  },
  updateAnnouncement({ commit, dispatch }, announcement) {
    commit(
      'updateAnnouncement',
      Object.values(normalize(announcement, schemas.announcement).entities.announcements)[0],
    );
    dispatch('getTagList', { root: true });
  },
  destroyAnnouncement({ commit, dispatch, state }, announcement) {
    if (state.announcements[announcement.id].tagList.length > 0) {
      dispatch('getTagList', { root: true });
    }
    commit('destroyAnnouncement', announcement);
    commit('destroySavedAnnouncement', announcement);
  },
  createComment({ commit, getters: documentGetters, rootState }, comment) {
    const announcementId = comment.announcementId.toString();
    const sectionId = documentGetters.getSectionIdByAnnouncementId(announcementId);
    commit('updateComment', comment);
    commit('addComment', { comment, announcementId });
    if (rootState.user.id !== comment.author.id) {
      commit('updateUnreadComments', {
        commentId: comment.id.toString(),
        announcementId,
        sectionId: sectionId && sectionId.toString(),
        type: 'increase',
      });
    }
  },
  updateComment({ commit }, comment) {
    commit(
      'updateComment',
      Object.values(normalize(comment, schemas.comment).entities.comments)[0],
    );
  },
  destroyComment({ commit }, comment) {
    commit('destroyComment', comment);
  },
  createReaction({ commit }, reaction) {
    commit('updateReaction', reaction);
    if (reaction.parentTextType === 'Comment') {
      commit('addCommentReaction', reaction);
    }
    if (reaction.parentTextType === 'Announcement') {
      commit('addAnnouncementReaction', reaction);
    }
  },
  destroyReaction({ commit }, reaction) {
    if (reaction.parentTextType === 'Comment') {
      commit('destroyCommentReaction', reaction);
    }
    if (reaction.parentTextType === 'Announcement') {
      commit('destroyAnnouncementReaction', reaction);
    }
  },
  toggleSavedAnnouncement({ commit }, payload) {
    commit('toggleSavedAnnouncement', payload);
  },
  setDraftComment({ commit }, payload) {
    commit('setDraftComment', payload);
  },
};

const mutations = {
  setAnnouncements(state, payload) {
    state.announcements = payload || {};
  },
  setComments(state, payload) {
    state.comments = payload || {};
  },
  setReactions(state, payload) {
    state.reactions = payload || {};
  },
  addAnnouncement(state, { announcement, sectionId }) {
    state.sections[sectionId].announcements.push(announcement.id);
  },
  updateAnnouncement(state, payload) {
    state.announcements[payload.id] = payload;
  },
  destroyAnnouncement(state, payload) {
    delete state.announcements[payload.id];
    const index = state.sections[payload.sectionId].announcements.indexOf(payload.id);
    state.sections[payload.sectionId].announcements.splice(index, 1);
  },
  addComment(state, { comment, announcementId }) {
    state.announcements[announcementId].comments.push(comment.id);
  },
  updateComment(state, payload) {
    state.comments[payload.id] = payload;
  },
  destroyComment(state, payload) {
    delete state.comments[payload.id];
    const index = state.announcements[payload.announcementId].comments.indexOf(payload.id);
    state.announcements[payload.announcementId].comments.splice(index, 1);
  },
  updateReaction(state, payload) {
    state.reactions[payload.id] = payload;
  },
  addCommentReaction(state, payload) {
    state.comments[payload.parentTextId].reactions.push(payload.id);
  },
  destroyCommentReaction(state, payload) {
    delete state.reactions[payload.id];
    const index = state.comments[payload.parentTextId].reactions.indexOf(payload.id);
    state.comments[payload.parentTextId].reactions.splice(index, 1);
  },
  addAnnouncementReaction(state, payload) {
    state.announcements[payload.parentTextId].reactions.push(payload.id);
  },
  destroyAnnouncementReaction(state, payload) {
    delete state.reactions[payload.id];
    const index = state.announcements[payload.parentTextId].reactions.indexOf(payload.id);
    state.announcements[payload.parentTextId].reactions.splice(index, 1);
  },
  toggleSavedAnnouncement(state, payload) {
    const announcementIndex = getSavedAnnouncementIndex(state, payload);
    if (announcementIndex === -1) {
      addSavedAnnouncement(
        state, {
          'documentId': payload.documentId,
          'sectionId': payload.sectionId,
          'announcementId': payload.announcementId,
        },
      );
    } else {
      removeSavedAnnouncement(
        state, {
          'documentId': payload.documentId,
          'sectionId': payload.sectionId,
          'announcementIndex': announcementIndex,
        },
      );
    }
  },
  destroySavedAnnouncement(state, payload) {
    const announcementIndex = getSavedAnnouncementIndex(
      state, {
        'documentId': state.document.id,
        'sectionId': payload.sectionId,
        'announcementId': payload.id,
      },
    );
    if (announcementIndex !== -1) {
      removeSavedAnnouncement(
        state,
        {
          'documentId': state.document.id,
          'sectionId': payload.sectionId,
          'announcementIndex': announcementIndex,
        },
      );
    }
  },
  setDraftComment(state, payload) {
    if (payload.content === '') return delete state.draftComments[payload.announcementId];

    const newPayload = {
      sectionId: payload.sectionId,
      content: payload.content,
    };

    state.draftComments[payload.announcementId] = newPayload;

    return newPayload;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
