<template>
  <div
    class="flex items-center"
    :class="$attrs.class"
  >
    <input
      v-bind="filteredAttrs"
      :id="value"
      type="radio"
      class="hidden"
      :value="checked"
      @click="$emit('update:modelValue', value);"
    >
    <label
      :for="value"
      class="transition-colors relative block w-4 h-4 mr-2 duration-100 ease-in-out rounded-full cursor-pointer"
      :class="checked ? 'bg-purple-500' : 'bg-gray-300'"
    >
      <div class="absolute inset-0 p-1">
        <div
          class="w-full h-full transition-opacity duration-100 ease-in-out bg-white rounded-full opacity-0"
          :class="checked && 'opacity-100'"
        />
      </div>
    </label>
    <label
      :for="value"
      class="flex-1 text-sm cursor-pointer select-none"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import useFilteredAttrs from '../../composables/useFilteredAttrs';

export default {
  inheritAttrs: false,
  props: {
    label: { type: String, required: true },
    value: { type: String, required: true },
    modelValue: { type: String, required: true },
  },
  emits: ['update:modelValue'],
  setup() {
    const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

    return { filteredAttrs };
  },
  computed: {
    checked() {
      return this.value === this.modelValue;
    },
  },
};
</script>
