<template>
  <base-modal
    :title="$t('documents.delete.message')"
    @close="beforeClose"
  >
    <backend-errors
      v-if="errors.length > 0"
      :errors="errors"
    >
      {{ $t('documents.delete.error') }}
    </backend-errors>
    <div class="mb-4">
      <i18n-t
        tag="p"
        keypath="documents.delete.confirm"
        scope="global"
      >
        <span class="font-bold text-purple-500">{{ document && document.title }}</span>
      </i18n-t>
    </div>
    <div class="flex justify-end">
      <mute-button
        id="destroy-document-modal-close-button"
        variant="white"
        class="mr-2"
        @click="hideDestroyDocumentModal"
      >
        {{ $t('documents.delete.no') }}
      </mute-button>
      <mute-button
        id="destroy-document-modal-submit-button"
        :disabled="loading"
        variant="purple"
        @click="destroyDocument"
      >
        {{ $t('documents.delete.yes') }}
      </mute-button>
    </div>
  </base-modal>
</template>

<script>
import documentsApi from '../../api/documents';
import backendErrors from '../shared/backend-errors.vue';

export default {
  components: { backendErrors },
  props: { document: { type: Object, default: null } },
  emits: ['close', 'destroyed-document', 'before-close'],
  data() {
    return {
      errors: [],
      loading: false,
    };
  },
  methods: {
    hideDestroyDocumentModal() {
      this.$emit('close');
    },
    async destroyDocument() {
      this.loading = true;

      try {
        await documentsApi.destroyDocument(this.document.id);
        this.$emit('destroyed-document', this.document.id);
        this.hideDestroyDocumentModal();
      } catch (error) {
        if (error.details) {
          this.errors = error.details;
        }
      }

      this.loading = false;
    },
    beforeClose() {
      this.$emit('before-close');
      this.hideDestroyDocumentModal();
      this.errors = [];
    },
  },
};
</script>
